import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditAbsenceDialogComponent } from 'src/app/my-sedgwick';
import { CsvParams, CsvService, srSpeak } from 'src/app/shared';
import { CalendarEvent } from 'src/app/shared/models/CalendarEvent';
import {
  AbsenceService,
  DismissDialogComponent,
  IncidentalAbsence,
  IncidentalAbsenceReason,
} from '../..';
import { ConfigurationService } from '../../services/configuration.service';
import { AbsenceDeleteDialogComponent } from './absence-delete-dialog.component';
import { ActivityService } from '../../services/activity.service';
import { AbsenceEditDialogComponent } from './absence-edit-dialog.component';
import { IncidentalWorkScheduleDialogComponent } from '../Incidental/incidental-work-schedule-dialog.component';

@Component({
  selector: 'app-absence-grid',
  template: `
    <div class="tw-w-full tw-flex tw-flex-wrap">
      <div class="tw-w-full" [hidden]="!showButtons">
        <mat-button-toggle-group
          name="displayMode"
          aria-label="Display Mode"
          value="list"
          #displayMode="matButtonToggleGroup"
        >
          <mat-button-toggle
            value="list"
            (change)="onValChange($event.value)"
            aria-label="Select to format absences in a list"
            ><mat-icon
              color="accent"
              aria-label="Select to format absences in a list"
              >list</mat-icon
            ></mat-button-toggle
          >
          <mat-button-toggle
            value="calendar"
            (change)="onValChange($event.value)"
            aria-label="Select to format absences in a calendar layout"
            ><mat-icon
              color="accent"
              aria-label="Select to format absences in a calendar layout"
              >date_range</mat-icon
            ></mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>

      <div class="app-pl2 tw-w-full md:tw-w-[20%]" *ngIf="searchEnabled"></div>

      <div class="app-pl2 tw-w-full md:tw-w-[20%]" *ngIf="searchEnabled">
        <mat-form-field>
          <mat-label>From</mat-label>
          <input matInput [matDatepicker]="fromDate" />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="fromDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #fromDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="app-pl1 tw-w-full md:tw-w-[20%]" *ngIf="searchEnabled">
        <mat-form-field>
          <mat-label>To</mat-label>
          <input matInput [matDatepicker]="toDate" />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="toDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #toDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div
        class="app-pl1 app-pt1 tw-w-full md:tw-w-[20%]"
        *ngIf="searchEnabled"
      >
        <app-button emphasis="High">Apply</app-button>
        <app-button emphasis="Low">Clear</app-button>
      </div>
    </div>
    <app-loading-panel [state]="absencesLoading ? 'Loading' : 'Loaded'" [width]="90">
    <ng-template #content>
    <div *ngIf="displayMode.value === 'calendar'">
      <div class="app-pt2"></div>
      <app-calendar
        [events]="calendarevents"
        toolbarRight="dayGridMonth,dayGridWeek,dayGridDay"
      >
      </app-calendar>

      <div
        *ngIf="isIncidentalAbsence4Feature"
        class="tw-w-full tw-flex tw-flex-row app-pt2"
      >
        <div class=" md:tw-w-[20%] tw-flex dotAllign">
          <div class="dot app-bg-color-green"></div>
          <div class="app-pr2 app-pl1 tw-font-bold">Approved</div>
        </div>
        <div class=" md:tw-w-[20%] tw-flex dotAllign">
          <div class="dot app-bg-color-sedgwick-blue"></div>
          <div class="app-pr2 app-pl1 tw-font-bold">Pending</div>
        </div>
        <div class=" md:tw-w-[20%] tw-flex dotAllign">
          <div class="dot app-bg-color-red"></div>
          <div class="app-pr2 app-pl1 tw-font-bold">Denied</div>
        </div>
      </div>
    </div>

    <div
      *ngIf="displayMode.value === 'list'"
      class="tw-flex tw-flex-wrap app-pt2"
    >
      <app-list
        class="tw-w-full"
        [dataSource]="absences"
        [template]="row"
        [filterEnabled]="filterEnabled"
        [exportEnabled]="true"
        exportButtonAriaLabel="Export and download these absence/s as a XLSX file"
        [noDataIcon]="noDataIcon"
        [noDataMessage]="noDataMessage"
        [noDataThinMessage]="noDataThinMessage"
        [toastInfoMessage]="toastInfoMessage"
        [displayInfoToastMsg]="displayInfoToastMsg"
        (export)="export()"
        [tableAriaLabel]="'Absences'"
      >
        <ng-container header>
          <tr class="tw-flex tw-flex-wrap tw-w-full ">
            <app-list-header
              name="lastName"
              label="Name"
              class="tw-w-full md:tw-w-[22%]"
              [names]="['firstName', 'lastName', 'empId']"
            ></app-list-header>

            <app-list-header
              name="absenceReason"
              label="Reason"
              class="tw-w-full md:tw-w-[18%]"
            ></app-list-header>

            <app-list-header
              [names]="['absenceStartDate', 'absenceStartTime']"
              name="absenceStartDate"
              label="Start"
              class="tw-w-full md:tw-w-[15%]"
              filterType="Date"
            ></app-list-header>

            <app-list-header
              [names]="['absenceEndDate', 'absenceEndTime']"
              name="absenceEndDate"
              label="End"
              class="tw-w-full md:tw-w-[15%]"
              filterType="Date"
            ></app-list-header>

            <app-list-header
              name="estimatedRTWDate"
              label="Detail"
              class="tw-w-full md:tw-w-[25%]"
              [names]="['referenceNumber', 'estimatedRtw', 'shifts']"
            ></app-list-header>

            <app-list-header
              name="action"
              label="Actions"
              class="tw-w-full md:tw-w-[5%]"
              [ngClass]="isActionEnable ? 'tw-block' : 'tw-hidden'"
              [sortEnabled]="false"
            ></app-list-header>
          </tr>
        </ng-container>
      </app-list>
    </div>
    <ng-template #row let-element>
      <tr class="tw-flex tw-flex-wrap tw-w-full ">
        <td class="tw-w-full md:tw-w-[22%]">
          <app-value label="Name" layout="row-xs">
            <div value>
              <a
                routerLink=""
                (click)="
                  absenceService.navigateToAbsences(
                    element.empUnum,
                    element.firstName + ' ' + element.lastName,
                    element.empId
                  )
                "
                *ngIf="employeeLinkEnabled; else noLink"
              >
                <b>
                  {{ element.firstName }} {{ element.lastName }} -
                  {{ element.empId }}</b
                >
              </a>
              <ng-template #noLink>
                <b
                  >{{ element.firstName }} {{ element.lastName }} -
                  {{ element.empId }}</b
                >
              </ng-template>
            </div>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[18%]">
          <app-value
            label="Reason"
            layout="row-xs"
            [tabindex]="element.absenceReason ? '0' : '-1'"
          >
            <div value>
              <div class="tw-w-full">
                {{
                  element.absenceReason +
                    (showReasonSubtype ? ' - ' + element.typeOfCallOff : '')
                }}
              </div>
            </div>
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[15%]">
          <app-value
            label="Start"
            [tabindex]="element.absenceStartDate ? '0' : '-1'"
            layout="row-xs"
            [value]="
              showTimeStamp
                ? (element.absenceStartDate | format : 'dateTime')
                : (element.absenceStartDate | format : 'date')
            "
          >
          </app-value>
        </td>
        <td class="tw-w-full md:tw-w-[15%]">
          <app-value
            [tabindex]="element.absenceEndDate ? '0' : '-1'"
            label="End"
            layout="row-xs"
            [value]="
              showTimeStamp
                ? (element.absenceEndDate | format : 'dateTime')
                : (element.absenceEndDate | format : 'date')
            "
          >
          </app-value>
        </td>

        <td class="tw-w-full md:tw-w-[25%] pmt-header-init">
          <app-value
            [tabindex]="'0'"
            label="Detail"
            labelStyle="pmt-header-init"
            layout="row-xs"
          >
            <div value>
              <div class="tw-w-full" *ngIf="!isIncidentalAbsence4Feature">
                <b class="tw-w-full">Reference: </b>
                {{ element.referenceNumber }}
              </div>
              <div class="tw-w-full" *ngIf="isIncidentalAbsence4Feature">
                <b class="tw-w-full">Absence Number: </b>
                {{ element.absenceNumber }}
              </div>
              <div class="tw-w-full">
                <b>Return Date: </b>
                {{
                  showTimeStamp
                    ? (element.estimatedRTWDate | format : 'dateTime')
                    : (element.estimatedRTWDate | format : 'date')
                }}
              </div>
              <div class="tw-w-full" *ngIf="element.shiftsMissed">
                <b>Work Days Absent: </b>{{ element.shiftsMissed }}
              </div>
              <div class="tw-w-full" *ngIf="element.duration">
                <b>Duration: </b>{{ element.duration }}
              </div>
              <div
                class="tw-w-full"
                *ngIf="element.absenceStatus && isIncidentalAbsence4Feature"
              >
                <b>Status: </b>{{ element.absenceStatus }}
              </div>
            </div>
          </app-value>
        </td>

        <app-value
          class="tw-w-full md:tw-w-[5%]"
          label="Actions"
          layout="row-xs"
          [ngClass]="isActionEnable === true ? 'tw-block' : 'tw-hidden'"
        >
          <app-icon-button
            value
            mat-icon-button
            icon="more_vert"
            label="Actions"
            [attr.aria-label]="
              'Actions menu for ' +
              element.firstName +
              ' ' +
              element.lastName +
              ' absence'
            "
            [matMenuTriggerFor]="actionMenu"
            [attr.aria-expanded]="isActionsMenuExpanded"
            tabindex="0"
            role="button"
            (click)="toggleMenu()"
            (keydown.enter)="toggleMenu()"
          >
            <!-- <mat-icon color="accent">more_vert</mat-icon> -->
          </app-icon-button>
          <mat-menu #actionMenu="matMenu" role="menu" (closed)="toggleMenu()">
            <button
              mat-menu-item
              (click)="openEditAbsencePopup(element)"
              *ngIf="showEditOption"
            >
              <mat-icon color="accent">open_in_new</mat-icon>
              <span>Edit</span>
            </button>
            <button
              *ngIf="showRemoveOption"
              mat-menu-item
              (click)="openRemoveAbsencePopup(element)"
            >
              <mat-icon color="accent">highlight_off</mat-icon>
              <span>Remove</span>
            </button>
            <button
              *ngIf="showWorkSchedule"
              mat-menu-item
              (click)="openWorkSchedulePopup(element)"
            >
              <mat-icon color="accent">list_alt</mat-icon>
              <span>Work Schedule</span>
            </button>
          </mat-menu>
        </app-value>
      </tr>
   
    </ng-template>
    </ng-template>
    </app-loading-panel>
  `,
  styles: [
    `
      .heading-vtop {
        vertical-align: top;
      }
      .dot {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: inline-block;
      }

      @media (max-width: 480px) {
        .dot {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          display: inline-block;
        }
      }
      .app-bg-color-green {
        background-color: var(--color-sedgwick-green);
      }
      .app-bg-color-sedgwick-blue {
        background-color: var(--color-sedgwick-blue);
      }

      .app-bg-color-input-grey {
        background-color: var(--color-input-grey);
      }

      .app-bg-color-red {
        background-color: var(--color-red);
      }
      @media (max-width: 350px) {
        .dotAllign {
          padding-left: 37%;
          text-align: center;
        }
      }
    `,
  ],
})
export class AbsenceGridComponent implements OnInit {
  @Input()
  absences: IncidentalAbsence[];

  @Input()
  absencesLoading : boolean = true;

  @Input()
  reasonData: IncidentalAbsenceReason[];

  calendarevents: CalendarEvent[] = [];

  @Input()
  searchEnabled: boolean = false;

  @Input()
  employeeLinkEnabled: boolean = true;

  @Input()
  isManager: boolean = false;

  @Input()
  showButtons: boolean = true;

  @Input()
  filterEnabled: boolean = false;

  @Input()
  noDataIcon: string = 'search';

  @Input()
  noDataMessage: string = 'There are no absences.';

  @Input()
  noDataThinMessage: string = '';

  isActionEnable: boolean = true;

  @Input()
  toastInfoMessage: string = '';

  @Input()
  displayInfoToastMsg: boolean = false;

  @Input()
  hideRef: boolean = false;

  @Input()
  ownAbsence: boolean = false;

  isActionsMenuExpanded: boolean = false;

  showTimeStamp: boolean = true;
  showReasonSubtype: boolean = true;
  isIncidentalAbsence4Feature: boolean = false;
  showLegend: boolean = false;
  showRemoveOption: boolean = false;
  showWorkSchedule: boolean = false;
  isIncidentalAbsences5Feature: boolean = true;
  showEditOption: boolean = true;
  isIncidentalAbsence5Feature: boolean = false;

  constructor(
    public dialog: MatDialog,
    private csvService: CsvService,
    private config: ConfigurationService,
    public absenceService: AbsenceService,
    private activityService: ActivityService
  ) {}

  async ngOnInit() {
    this.isActionEnable = await this.config.getConfigFeatureAccessAsync(
      'UpdateIncidentalAbsences5'
    );
    this.isIncidentalAbsence4Feature =
      await this.config.getConfigFeatureAccessAsync('IncidentalAbsence');
    //this.isIncidentalAbsence4Feature = true;
    this.isIncidentalAbsence5Feature =
      await this.config.getConfigFeatureAccessAsync('IncidentalAbsences5');
    this.showWorkSchedule = this.isIncidentalAbsence4Feature;
    this.showRemoveOption =
      !this.isIncidentalAbsence4Feature ||
      (this.isIncidentalAbsence4Feature && !this.ownAbsence);
    this.showEditOption =
      (this.isIncidentalAbsence4Feature && !this.ownAbsence) ||
      this.isIncidentalAbsence5Feature
        ? true
        : false;
    this.showLegend = this.isIncidentalAbsence4Feature;
    this.showTimeStamp = this.showReasonSubtype =
      !this.isIncidentalAbsence4Feature;

    this.activityService.alertSelected.subscribe((sub: string) => {
      this.absences = this.absences.filter((_) => _.absenceNumber !== sub);
    });
  }

  async onValChange(value: any) {
    if (value == 'calendar') {
      this.calendarevents = [];
      this.calendarevents = await this.absenceService.generateEventsFromAbsence(
        this.absences
        ///false
      );
    }
  }

  dismiss() {
    this.dialog.open(DismissDialogComponent, {
      data: {
        title: 'Cancel Absence',
        message: 'Press Confirm to cancel this absence.',
      },
    });
  }

  export() {
    const csvParams = new CsvParams();
    csvParams.includeTimeWithDates = true;
    csvParams.rows = this.absences;
    if (this.isIncidentalAbsence4Feature) {
      csvParams.keys = [
        'firstName',
        'lastName',
        'empId',
        'absenceReason',
        'absenceStartDate',
        'absenceEndDate',
        'absenceNumber',
        'estimatedRTWDate',
        'duration',
        'absenceStatus',
      ];
      csvParams.headers = [
        'First Name',
        'Last Name',
        'Employee Id',
        'Reason',
        'Start',
        'End',
        'Absence Number',
        'Return Date',
        'Duration',
        'Status',
      ];
    } else {
      csvParams.keys = [
        'firstName',
        'lastName',
        'empId',
        'absenceReason',
        'typeOfCallOff',
        'absenceStartDate',
        'absenceEndDate',
        'absenceNumber',
        'estimatedRTWDate',
        'shiftsMissed',
      ];
      csvParams.headers = [
        'First Name',
        'Last Name',
        'Employee Id',
        'Reason',
        'Secondary Reason',
        'Start',
        'End',
        'Reference',
        'Return Date',
        'Work Days Absent',
      ];
    }
    this.csvService.download(csvParams);
  }

  openEditAbsencePopup(absence: IncidentalAbsence) {
    const dialogRef = this.isIncidentalAbsence4Feature
      ? this.dialog.open(AbsenceEditDialogComponent)
      : this.dialog.open(EditAbsenceDialogComponent);
    const instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.absence = absence;
    instance.reasonData = this.reasonData;
  }

  openRemoveAbsencePopup(absence: IncidentalAbsence) {
    const dialogRef = this.dialog.open(AbsenceDeleteDialogComponent);
    const instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.absence = absence;
  }

  openWorkSchedulePopup(absence: IncidentalAbsence) {
    const dialogRef = this.dialog.open(IncidentalWorkScheduleDialogComponent);
    const instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.absence = absence;
  }

  toggleMenu() {
    this.isActionsMenuExpanded = !this.isActionsMenuExpanded;
    if (this.isActionsMenuExpanded) {
      srSpeak('expanded', 'polite');
    }
  }
}
