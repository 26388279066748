import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NewClaim, SnackbarService, SnackbarType } from 'src/app/shared';
import { SaveAlertActionRequest } from 'src/app/shared/models/alert';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import { ActivityService } from '../../services/activity.service';
import { Session } from '../../services/session.service';
import { UserRoleService } from '../../services/user-role.service';

@Component({
  selector: 'app-new-claims-edit',
  template: `
    <div class="container dialog-container">
      <form [formGroup]="formGroup" class="tw-flex tw-flex-wrap tw-w-full">
        <app-action-row class="tw-w-full tw-items-center">
          <h2 left1 class="app-header-2">Confirmation</h2>
          <div right1 style="text-align: left">
            <button
              mat-icon-button
              (click)="closeDialog()"
              tabindex="0"
              aria-label="Close modal"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </app-action-row>

        <div class="tw-w-full message" *ngIf="!review">
          Please review and update if needed. Press Next to continue.
        </div>
        <div class="tw-w-full message" *ngIf="review">
          <span *ngIf="formGroup.dirty"
            >Press Confirm to update claim and dismiss this new claim
            activity.</span
          >
          <span *ngIf="!formGroup.dirty"
            >Press Confirm to dismiss this new claim activity.</span
          >
        </div>

        <app-value label="Claim" layout="col" class="tw-w-full app-pb1">
          <app-claim-label
            value
            [claim]="newClaim"
            [disabled]="true"
            [hideWatchList]="true"
            [noLink]="true"
            layout="row"
          ></app-claim-label>
        </app-value>
        <app-value label="Name" layout="col" class="tw-w-full app-pb2">
          <app-claimant-label
            value
            [claimant]="newClaim"
            [disabled]="true"
          ></app-claimant-label>
        </app-value>

        <app-value
          label="First Day Absent"
          [value]="newClaim.firstDayAbsent | format : 'date'"
          layout="col"
          class="tw-w-full app-pb2"
          *ngIf="
            !this.editClicked &&
            review &&
            (newClaim.lob === 'DS' || newClaim.lob === 'LV')
          "
        >
        </app-value>
        <app-value
          label="Last Day Worked"
          [value]="newClaim.lastDayWorked | format : 'date'"
          layout="col"
          class="tw-w-full app-pb2"
          *ngIf="!this.editClicked && review && newClaim.lob === 'DS'"
        >
        </app-value>

        <app-value
          label="Hours Worked"
          [value]="newClaim.hoursWorked | number"
          layout="col"
          class="tw-w-full app-pb2"
          *ngIf="
            !this.editClicked &&
            review &&
            newClaim.lob === 'LV' &&
            newClaim.source !== 'L'
          "
        >
        </app-value>

        <app-date-control
          formControlName="firstDayAbsent"
          [formGroup]="formGroup"
          label="First Day Absent"
          [validationControl]="'First day absent'"
          ngDefaultControl
          class="tw-w-full"
          *ngIf="
            this.editClicked && (newClaim.lob === 'DS' || newClaim.lob === 'LV')
          "
        ></app-date-control>

        <app-date-control
          formControlName="lastDayWorked"
          [formGroup]="formGroup"
          label="Last Day Worked"
          [validationControl]="'Last day absent'"
          ngDefaultControl
          class="tw-w-full"
          *ngIf="this.editClicked && newClaim.lob === 'DS'"
        ></app-date-control>

        <app-text-control
          formControlName="hoursWorked"
          [formGroup]="formGroup"
          [validationControl]="'Hours worked'"
          label="Hours Worked"
          mask="0*.99"
          ngDefaultControl
          class="tw-w-full"
          *ngIf="this.editClicked && newClaim.lob === 'LV'"
        ></app-text-control>
        <div class="tw-w-full app-pt1"></div>
        <app-action-row class="tw-w-full" *ngIf="!review">
          <app-button
            right2
            emphasis="High"
            type="submit"
            (click)="validateForm()"
          >
            Next
          </app-button>
          <app-button right1 emphasis="Low" (click)="closeDialog()">
            Cancel
          </app-button>
        </app-action-row>

        <app-action-row
          class="tw-w-full"
          *ngIf="review || newClaim.source === 'L'"
        >
          <app-button
            *ngIf="formGroup.valid || newClaim.source === 'L'"
            right3
            emphasis="High"
            (click)="dismissAlert()"
          >
            Confirm
          </app-button>
          <app-button
            right2
            emphasis="Low"
            (click)="Edit()"
            *ngIf="
              newClaim.lob !== 'WC' &&
              newClaim.lob !== 'AD' &&
              !isController &&
              newClaim.source !== 'L'
            "
          >
            Edit
          </app-button>
          <app-button right1 emphasis="Low" (click)="closeDialog()">
            Cancel
          </app-button>
        </app-action-row>
      </form>
    </div>
  `,
  styles: [
    `
      .container {
        max-width: 400px;
        padding: 8px 16px 24px 16px;
      }
      .message {
        font-size: 14px;
        padding: 8px 0 24px 0px;
      }
    `,
  ],
})
export class NewClaimsEditComponent {
  review: boolean = false;
  editClicked: boolean = false;
  isController: boolean = false;
  saveAlertRequest: SaveAlertActionRequest;
  formGroup: FormGroup;
  //  = this.fb.group({
  //   lastDayWorked: [],
  //   firstDayAbsent: [],
  //   hoursWorked:[]
  // });

  constructor(
    private fb: FormBuilder,
    private activityService: ActivityService,
    private session: Session,
    private snackBarService: SnackbarService,
    public dialogRef: MatDialogRef<any>,
    private cdr: ChangeDetectorRef,
    private userRole: UserRoleService,
    @Inject(MAT_DIALOG_DATA) public newClaim: NewClaim
  ) {}

  ngOnInit(): void {
    this.initFormGroup();
    this.review = true; // Set based on condition newClaim.lob === 'WC'
    this.isController = this.userRole.controller;
    this.session.loadUser();

    //console.log(this.newClaim);
  }

  initFormGroup(): void {
    this.formGroup = this.fb.group({
      lastDayWorked: [this.getDateOrNull(this.newClaim.lastDayWorked)],
      firstDayAbsent: [this.getDateOrNull(this.newClaim.firstDayAbsent)],
      hoursWorked: [this.newClaim.hoursWorked],
    });
    if (this.newClaim.lob === 'DS') {
      this.formGroup
        .get('firstDayAbsent')
        ?.setValidators([
          customvalidations.required,
          customvalidations.dateGreaterThan(
            this.formGroup.get('lastDayWorked'),
            false,
            'First day absent cannot be before last day worked.'
          ),
        ]);
      this.formGroup
        .get('lastDayWorked')
        ?.setValidators([
          customvalidations.required,
          customvalidations.dateLessThan(
            this.formGroup.get('firstDayAbsent'),
            false,
            'Last day worked cannot be after first day absent.'
          ),
        ]);
    }

    if (this.newClaim.lob === 'LV') {
      this.formGroup
        .get('firstDayAbsent')
        ?.setValidators([customvalidations.required]);
      this.formGroup
        .get('hoursWorked')
        ?.setValidators([
          customvalidations.required,
          customvalidations.IsDecimal(),
          customvalidations.numberLessThan(
            8760,
            'Number of hours worked cannot exceed 8760'
          ),
        ]);
    }
    this.formGroup.updateValueAndValidity();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  validateForm() {
    this.formGroup.markAllAsTouched();
    this.review = this.formGroup.valid;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  dismissAlert() {
    if (this.formGroup.valid || this.newClaim.source == 'L') {
      const fda = this.formGroup.get('firstDayAbsent').value;
      const ldw = this.formGroup.get('lastDayWorked').value;
      this.saveAlertRequest = new SaveAlertActionRequest({
        alertMasterUid: Number(this.newClaim.alertId),
        action: 'DISMISSED - VOE',
        sourceSystem: this.newClaim.source,
        claimNumber: this.newClaim.claimNumber,
        claimId: this.newClaim.claimId,
        item: this.newClaim,
        type: 'NewClaim',
        examinerId: this.newClaim.examinerId,
        fdaDate: this.formGroup.get('firstDayAbsent').value
          ? typeof fda == 'object'
            ? fda.toLocaleDateString('en-US')
            : fda
          : null,
        hoursWorked: this.formGroup.get('hoursWorked').value,
        ldwDate: this.formGroup.get('lastDayWorked').value
          ? typeof ldw == 'object'
            ? ldw.toLocaleDateString('en-US')
            : ldw
          : null,
        lob: this.newClaim.lob,
        userOwns: this.newClaim.userOwns,
        NoteHeader: '',
      });
      if (
        this.session.user.emulatorContext &&
        this.session.user.emulatorContext.isEmulating
      ) {
        this.snackBarService.show(
          'Preview-only mode. Information not sent.',
          SnackbarType.Info
        );
      } else {
        this.activityService.saveAlertAction(this.saveAlertRequest);
        this.activityService.alertSelected.emit(
          this.saveAlertRequest.alertMasterUid.toString()
        );
      }

      // TODO: Call API to dismiss this new claims alert and update claim if modified.
      this.dialogRef.close();
    }
  }

  Edit() {
    this.editClicked = true;
    if (this.newClaim.lob === 'DS' || this.newClaim.lob === 'LV') {
      this.formGroup.controls['firstDayAbsent'].setValue(
        this.getDateOrNull(this.newClaim.firstDayAbsent)
      );
    }
    if (this.newClaim.lob === 'LV') {
      this.formGroup.controls['hoursWorked'].setValue(
        this.newClaim.hoursWorked
      );
    }
    if (this.newClaim.lob === 'DS') {
      this.formGroup.controls['lastDayWorked'].setValue(
        this.getDateOrNull(this.newClaim.lastDayWorked)
      );
    }
  }
  getDateOrNull(dateValue: any): Date | null {
    return dateValue instanceof Date
      ? dateValue
      : dateValue
      ? new Date(dateValue)
      : null;
  }
}
