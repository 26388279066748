import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  ConfigurationService,
  DisplayService,
  IncidentalAbsence,
  Session,
  UserRoleService,
} from '../..';
import { EditAbsenceDialogComponent } from 'src/app/my-sedgwick';
import { Router } from '@angular/router';
@Component({
  selector: 'app-absence-summary',
  template: `
    <app-panel
      [title]="title"
      [sentenceCase]="sentenceCase"
      [largeHeader]="true"
    >
      <app-action-row class="tw-w-full" *ngIf="display.reportClaimSignal()">
        <app-button right1 emphasis="High" (click)="reportClaim()"
          >Submit an absence</app-button
        >
      </app-action-row>
      <app-absence-grid
        [absences]="absences"
        [filterEnabled]="true"
        [employeeLinkEnabled]="false"
        [absencesLoading]="absencesLoading"
        [hideRef]="true"
      ></app-absence-grid>

      <ng-template #dialogReportNewClaim>
        <app-dialog
          title="Confirmation"
          [width]="700"
          [showActions]="false"
          [showHeader]="false"
          ariaLabel="Confirmation dialog"
          ariaLabelMessage="Close announcement popup"
        >
          <app-report-new-claim></app-report-new-claim>
        </app-dialog>
      </ng-template>
    </app-panel>
  `,
  styles: [],
})
export class AbsenceSummaryComponent implements OnInit {
  @ViewChild('dialogReportNewClaim') dialogRefReportNewClaim: TemplateRef<any>;
  @Input()
  title: string;

  @Input()
  sentenceCase: boolean = true;

  @Input()
  absences: IncidentalAbsence[];

  @Input()
  absencesLoading: boolean;

  hasReportClaim = false;
  isIncAbsence: boolean = false;

  constructor(
    private session: Session,
    public dialog: MatDialog,
    private config: ConfigurationService,
    private router: Router,
    public display: DisplayService,
    public userRole: UserRoleService
  ) {}

  async ngOnInit() {
    this.isIncAbsence = await this.config.getConfigFeatureAccess(
      'IncidentalAbsence'
    );
    this.hasReportClaim =
      !this.session.user.hasIntake ||
      this.session.user.hasIntake === undefined ||
      (this.isIncAbsence && this.userRole.employee)
        ? false
        : true;
  }

  openEditAbsencePopup(absence: IncidentalAbsence) {
    const dialogRef = this.dialog.open(EditAbsenceDialogComponent);
    const instance = dialogRef.componentInstance;
    instance.dialogRef = dialogRef;
    instance.absence = absence;
  }

  reportClaim() {
    if (this.config.getConfigFeatureAccess('IncidentalAbsences5')) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: 'Confirmation',
      };
      this.dialog.open(this.dialogRefReportNewClaim, dialogConfig);
    } else {
      this.router.navigate(['employeeSearch'], {});
    }
  }
}
