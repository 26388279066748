import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AbsenceService,
  ClaimantService,
  IncidentalAbsence,
  IncidentalAbsenceReason,
  Session,
  UserRoleService,
} from '../..';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import { DatePipe } from '@angular/common';
import { AbsenceDuration } from '../../models/incidental-absence';
import { SnackbarService, SnackbarType } from 'src/app/shared';
import { emulator } from '../../services/emulator.service';

@Component({
  selector: 'app-absence-edit-dialog',
  template: `
    <app-dialog
      #absenceEditDialog
      [title]="dialogTitle"
      [ariaLabel]="
        !submitSuccessful ? 'Edit Absence dialog' : 'Confirmation dialog'
      "
      [showHeader]="true"
      [confirmLabel]="'Submit'"
      [cancelLabel]="'Cancel'"
      [reviewLabel]="'Review'"
      [btnConfirmAltText]="'Submit'"
      [btnConfirmTitleText]="'Submit'"
      [btnCloseAltText]="'Cancel'"
      [btnCloseTitleText]="'Cancel'"
      [showBack]="
        isReviewing && !this.submitSuccessful && !submitted ? true : false
      "
      [showCancel]="!this.submitSuccessful && !submitted ? true : false"
      [showReview]="
        !isReviewing && !this.submitSuccessful && !submitted ? true : false
      "
      [showConfirm]="
        isReviewing && !this.submitSuccessful && !submitted ? true : false
      "
      (confirmEvent)="submit($event)"
      (cancelEvent)="cancel($event)"
      (reviewEvent)="review($event)"
      (backEvent)="back($event)"
    >
      <app-loading-panel [state]="loading ? 'Loading' : 'Loaded'" [width]="90">
        <ng-template #content>
          <div class="tw-w-full" *ngIf="submitted && submitSuccessful">
            <br />
            <app-toast type="Success">
              {{ successMessage }}
            </app-toast>
          </div>
          <div class="tw-w-full" *ngIf="submitted && !submitSuccessful">
            <br />
            <app-toast type="Error">
              {{ submissionFailMessage }}
            </app-toast>
          </div>

          <ng-container *ngIf="!isReviewing && !submitSuccessful && !submitted">
            <app-value label="Name" layout="row-xs">
              <div value>
                <a
                  routerLink=""
                  (click)="redirectToEmployeeAbsences()"
                  *ngIf="employeeLinkEnabled; else noLink"
                >
                  <b>
                    {{ absence.firstName }} {{ absence.lastName }} -
                    {{ absence.empId }}</b
                  >
                </a>
                <ng-template #noLink>
                  <b
                    >{{ absence.firstName }} {{ absence.lastName }} -
                    {{ absence.empId }}</b
                  >
                </ng-template>
              </div>
            </app-value>
            <div class="tw-w-full" *ngIf="noChangesMade">
              <br />
              <app-toast type="Info">
                {{ noChangeMadeMessage }}
              </app-toast>
            </div>
            <form
              [formGroup]="editAbsenceFormGroup"
              class="tw-flex tw-flex-wrap tw-w-full"
            >
              <div class="tw-w-full md:tw-w-full app-pb2">
                <p>
                  <strong>Reason:</strong>
                  {{ absence.absenceReason }}
                </p>
              </div>

              <div class="tw-w-full md:tw-w-[33%]">
                <app-date-control
                  formControlName="startDate"
                  [formGroup]="editAbsenceFormGroup"
                  label="Start Date"
                  validationControl="Start Date"
                  ngDefaultControl
                ></app-date-control>
              </div>

              <div class="tw-w-full md:tw-w-[33%]">
                <app-date-control
                  formControlName="endDate"
                  [formGroup]="editAbsenceFormGroup"
                  label="End Date"
                  validationControl="End Date"
                  ngDefaultControl
                ></app-date-control>
              </div>

              <div class="tw-w-full md:tw-w-[33%]">
                <app-date-control
                  formControlName="dateOfReturn"
                  [formGroup]="editAbsenceFormGroup"
                  label="Return to Work Date"
                  validationControl="Return to Work Date"
                  ngDefaultControl
                ></app-date-control>
              </div>
              <div class="tw-w-full app-pb1">
                <span><strong>Absence hours and minutes</strong></span>
              </div>

              <div
                formArrayName="datesArray"
                *ngFor="let dateGroup of datesArray.controls; let i = index"
                class="tw-w-full md:tw-w-[14%] tw-p-2"
              >
                <div
                  class="tw-flex tw-flex-wrap tw-w-full app-pb4"
                  [formGroupName]="i"
                >
                  <div class="tw-w-full app-pb1">
                    <span
                      ><strong
                        >{{
                          dateGroup
                            .get('absenceDate')
                            ?.value.toLocaleDateString('en-US', {
                              weekday: 'long'
                            })
                        }}
                        -
                        {{
                          dateGroup
                            .get('absenceDate')
                            ?.value.toLocaleDateString()
                        }}</strong
                      ></span
                    >
                  </div>
                  <div class="tw-w-1/2">
                    <app-text-control
                      formControlName="hours"
                      label="Hours"
                      [formGroup]="asFormGroup(dateGroup)"
                      type="number"
                      class="tw-w-1/2"
                      [minTypeNumberValue]="0"
                      [maxTypeNumberValue]="19"
                      ngDefaultControl
                      [step]="1"
                    ></app-text-control>
                  </div>
                  <div class="tw-w-1/2">
                    <app-text-control
                      [formGroup]="asFormGroup(dateGroup)"
                      formControlName="minutes"
                      label="Minutes"
                      type="number"
                      class="tw-w-1/2"
                      [minTypeNumberValue]="0"
                      [maxTypeNumberValue]="59"
                      ngDefaultControl
                      [step]="1"
                    ></app-text-control>
                  </div>
                </div>
              </div>
            </form>
          </ng-container>

          <ng-container *ngIf="isReviewing && !submitSuccessful && !submitted">
            <div>
              <strong>Please review your changes.</strong>
              <p>
                <strong>Reason:</strong>
                {{ absence.absenceReason }}
              </p>
              <p>
                <strong>Start Date:</strong>
                {{ editAbsenceFormGroup.get('startDate').value | date }}
              </p>
              <p>
                <strong>End Date:</strong>
                {{ editAbsenceFormGroup.get('endDate').value | date }}
              </p>
              <p>
                <strong>Date of Return:</strong>
                {{ editAbsenceFormGroup.get('dateOfReturn').value | date }}
              </p>
              <div *ngFor="let date of datesArray.controls">
                <p>
                  <strong>Date : </strong>
                  {{ date.get('absenceDate').value | date : 'MMM d, y' }}
                  <strong>Duration : </strong>
                  {{ date.get('hours').value }} Hours and
                  {{ date.get('minutes').value }} minutes
                </p>
              </div>
            </div>
          </ng-container> </ng-template
        >>
      </app-loading-panel>
    </app-dialog>
  `,
  styles: [],
})
export class AbsenceEditDialogComponent implements OnInit {
  @Input() dialogRef: MatDialogRef<any>;
  @Input() absence: IncidentalAbsence;
  @Input() reasonData: IncidentalAbsenceReason[];

  loading: boolean = true;
  noChangesMade: boolean = false;
  userOwnsAbsence: boolean = false;
  employeeLinkEnabled: boolean = false;
  dialogTitle: string = 'Edit Absence';
  editAbsenceFormGroup: FormGroup;
  isReviewing: boolean = false;
  reviewed: boolean = false;
  submitted: boolean = false;
  submitSuccessful: boolean = false;
  submitFail: boolean = false;
  successMessage: string =
    'Your changes have been submitted and will be available soon.';
  noChangeMadeMessage: string = 'No changes have been made.';
  submissionFailMessage: string = 'Submission Failed';
  currentAbsenceFromTams: any;

  private initialValues: any;

  constructor(
    private formBuilder: FormBuilder,
    private session: Session,
    public userRole: UserRoleService,
    public absenceService: AbsenceService,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    private claimantService: ClaimantService,
    private enumalatorService: emulator,
    private snackbarService: SnackbarService
  ) {
    this.initForm();
  }

  async ngOnInit() {
    const absencesFromTams = await this.claimantService.GetIncidentalAbsences(
      this.absence.empUnum
    );
    this.currentAbsenceFromTams = absencesFromTams.find(
      (x) =>
        x.absenceNumber == this.absence.absenceNumber &&
        x.absenceEntryId == this.absence.absenceUid
    );
    //console.log('ABSENCE FROM TAMS', this.currentAbsenceFromTams);
    this.userOwnsAbsence =
      this.session.user.empUnum === this.absence.empUnum ? true : false;
    this.employeeLinkEnabled = !this.userOwnsAbsence;
    await this.setInitialValues();
    this.setupDateChangeHandlers();
    // console.log('ABSENCE FROM DB', this.absence);
    this.initialValues = JSON.parse(
      JSON.stringify(this.editAbsenceFormGroup.value)
    );
    this.editAbsenceFormGroup.get('endDate').valueChanges.subscribe(() => {
      const returnToWorkControl = this.editAbsenceFormGroup.get('dateOfReturn');
      if (returnToWorkControl) {
        returnToWorkControl.updateValueAndValidity();
      }
    });
    this.loading = false;
  }

  //setup form

  initForm() {
    this.editAbsenceFormGroup = this.formBuilder.group({
      startDate: [
        null,
        [
          Validators.required,

          customvalidations.beginDateNotAfterEndDate(
            'Error : Start date cannot be after the end date. '
          ),
        ],
      ],
      endDate: [
        null,
        [
          Validators.required,

          customvalidations.endDateNotBeforeBeginDate(
            'Error : End date cannot be before start date.'
          ),
          customvalidations.returnToWorkDateNotBeforeEndDate(
            'Error : Return to work date cannot be before end date.'
          ),
          customvalidations.returnToWorkDateNotBeforeBeginDate(
            'Error : Return to work date cannot be before start date.'
          ),
        ],
      ],
      dateOfReturn: [
        null as Date | null,
        [
          customvalidations.dateNotMoreThanMonthInFuture(
            'Error : Return to work date cannot be a month or more in the future. '
          ),
          customvalidations.returnToWorkDateNotBeforeEndDate(
            'Error : Return to work date cannot be before end date.'
          ),
          customvalidations.returnToWorkDateNotBeforeBeginDate(
            'Error : Return to work date cannot be before start date.'
          ),
        ],
      ],

      datesArray: this.formBuilder.array([]),
    });
  }

  async setInitialValues() {
    const startDate = new Date(this.absence.absenceStartDate);
    const endDate = new Date(this.absence.absenceEndDate);

    this.editAbsenceFormGroup.get('startDate').setValue(startDate);
    this.editAbsenceFormGroup.get('endDate').setValue(endDate);
    this.editAbsenceFormGroup
      .get('dateOfReturn')
      .setValue(
        this.absence.estimatedRTWDate
          ? new Date(this.absence.estimatedRTWDate)
          : null
      );
    this.populateDatesArray();
  }

  populateDatesArray() {
    if (
      !this.currentAbsenceFromTams ||
      !this.currentAbsenceFromTams.absenceDates
    ) {
      return;
    }

    this.datesArray.clear();

    this.currentAbsenceFromTams.absenceDates.forEach((absence: any) => {
      const { hours, minutes } = this.toHoursAndMinutes(
        parseInt(absence.duration, 10)
      );
      const absenceDate = new Date(absence.dateOfAbsence);

      const dateGroup = this.formBuilder.group({
        absenceDate: [absenceDate],
        hours: [
          hours,
          [
            Validators.required,
            customvalidations.IsNumeric,
            Validators.min(0),
            Validators.max(19),
          ],
        ],
        minutes: [
          minutes,
          [
            Validators.required,
            customvalidations.IsNumeric,
            Validators.min(0),
            Validators.max(59),
          ],
        ],
      });

      this.datesArray.push(dateGroup);
    });
  }

  addAbsentDay(hours: number, minutes: number, currentDate: Date) {
    const dateGroup = this.formBuilder.group({
      absenceDate: [currentDate],
      hours: [
        hours,
        [
          Validators.required,
          customvalidations.IsNumeric,
          Validators.min(0),
          Validators.max(19),
        ],
      ],
      minutes: [
        minutes,
        [
          Validators.required,
          customvalidations.IsNumeric,
          Validators.min(0),
          Validators.max(59),
        ],
      ],
    });
    this.datesArray.push(dateGroup);
  }
  //setup Form End

  //handle form behavior
  setupDateChangeHandlers() {
    this.editAbsenceFormGroup.get('startDate').valueChanges.subscribe(() => {
      this.updateDatesArrayIfValid();
    });

    this.editAbsenceFormGroup.get('endDate').valueChanges.subscribe(() => {
      this.updateDatesArrayIfValid();
    });
  }

  async updateDatesArrayIfValid() {
    this.loading = true;
    const startDate = this.editAbsenceFormGroup.get('startDate').value;
    const endDate = this.editAbsenceFormGroup.get('endDate').value;

    if (startDate && endDate && startDate <= endDate) {
      await this.updateDatesArray(new Date(startDate), new Date(endDate));
    }
    this.loading = false;
  }

  async updateDatesArray(startDate: Date, endDate: Date) {
    const preservedData = new Map<string, { hours: number; minutes: number }>();
    this.datesArray.controls.forEach((control: AbstractControl) => {
      const date = control.get('absenceDate').value;
      const formattedDate = this.formatDateForComparison(date);
      const hours = control.get('hours').value;
      const minutes = control.get('minutes').value;
      preservedData.set(formattedDate, { hours, minutes });
    });

    this.datesArray.clear();
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const formattedDate = this.formatDateForComparison(currentDate);
      const isWeekday = currentDate.getDay() >= 1 && currentDate.getDay() <= 5;

      // Check if the date exists in preserved data
      const existingData = preservedData.get(formattedDate);
      const hours = existingData ? existingData.hours : isWeekday ? 8 : 0;
      const minutes = existingData ? existingData.minutes : 0;

      this.addAbsentDay(hours, minutes, new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  //end handle form behavior

  //validation and submission
  checkForChanges(): boolean {
    const currentValues = JSON.stringify(
      this.editAbsenceFormGroup.value,
      (key, value) => {
        if (typeof value === 'function') {
          return value.toString();
        }
        return value;
      }
    );

    function deepCompare(str1: string, str2: string): boolean {
      try {
        const obj1 = JSON.parse(str1);
        const obj2 = JSON.parse(str2);
        return JSON.stringify(obj1) === JSON.stringify(obj2);
      } catch (error) {
        //console.log('JSON parsing error:', error);
        return false;
      }
    }

    return deepCompare(this.initialValues, currentValues);
  }

  getAbsenceDurations(): AbsenceDuration[] {
    return this.datesArray.controls.map((dateGroup: AbstractControl) => {
      const date = dateGroup.get('absenceDate').value;
      const hours = dateGroup.get('hours').value;
      const minutes = dateGroup.get('minutes').value;
      const totalMinutes = hours * 60 + minutes;

      date.setHours(0, 0, 0, 0);

      return {
        dateOfAbsence: date,
        duration: totalMinutes,
      };
    });
  }
  async updateIncidentalAbsence() {
    if (this.enumalatorService.showAuditorInfo()) {
      this.dialogRef.close();
      this.snackbarService.show(
        'Preview only mode. Information not sent',
        SnackbarType.Info
      );
      return;
    } else {
      const absenceDurations = this.getAbsenceDurations();

      const editedAbsence: IncidentalAbsence = {
        empId: this.absence.empId,
        firstName: this.absence.firstName,
        lastName: this.absence.lastName,
        absenceEndDate: this.editAbsenceFormGroup.get('endDate').value,
        absenceStartDate: this.editAbsenceFormGroup.get('startDate').value,
        estimatedRTWDate: this.editAbsenceFormGroup.get('dateOfReturn').value,
        absenceEndTime: this.editAbsenceFormGroup.get('endDate').value,
        absenceStartTime: this.editAbsenceFormGroup.get('startDate').value,
        estimatedRTWTime: this.editAbsenceFormGroup.get('dateOfReturn').value,
        absenceReason: this.absence.absenceReason,
        typeOfCallOff: this.absence.typeOfCallOff,
        absenceType: this.absence.absenceType,
        absenceNumber: this.absence.absenceNumber,
        shiftsMissed: this.absence.shiftsMissed,
        dateReported: this.absence.dateReported,
        absenceStatus: this.absence.absenceStatus,
        absenceReportedBy: this.absence.absenceReportedBy,
        empUnum: this.absence.empUnum,
        referenceNumber: this.absence.referenceNumber,
        ClaimLevelFeatures: [],
        duration: this.absence.duration,
        absenceUid: this.absence.absenceUid,
        rtwChanged:
          this.formatDateForComparison(this.absence.estimatedRTWDate) !==
          this.formatDateForComparison(
            this.editAbsenceFormGroup.get('dateOfReturn').value
          ),
        absenceDurations: absenceDurations,
      };
      //console.log('RTW from db', this.absence.estimatedRTWDate);
      // console.log(
      //   'RTW from form',
      //   this.editAbsenceFormGroup.get('dateOfReturn').value
      // );
      // console.log('EDITED ABSENCE', editedAbsence);
      const returnValue = await this.absenceService.editIncidentalAbsence(
        editedAbsence
      );
      // console.log('SUBMISSION STATUS', returnValue);
      this.submitted = true;
      this.submitSuccessful = returnValue === 'SUCCESS' ? true : false;
      this.dialogTitle = 'Confirmation';
    }
  }

  //End Validation and Submission

  //Modal events
  submit(event: Event) {
    if (this.editAbsenceFormGroup.valid) {
      this.updateIncidentalAbsence();
    } else {
      console.error('Form is invalid');
    }
  }

  cancel(event: Event) {
    this.dialogRef.close();
  }

  review(event: Event) {
    if (this.editAbsenceFormGroup.valid && !this.editAbsenceFormGroup.touched) {
      this.showNoChangesToast();
    } else if (this.editAbsenceFormGroup.valid) {
      this.massageDateValues();
      this.isReviewing = true;

      const endDate = new Date(this.editAbsenceFormGroup.get('endDate').value);
      const returnDate = new Date(
        this.editAbsenceFormGroup.get('dateOfReturn').value
      );
    } else {
      this.editAbsenceFormGroup.markAllAsTouched();
    }
  }

  back(event: Event) {
    this.isReviewing = false;
  }

  showNoChangesToast() {
    this.noChangesMade = true;
    setTimeout(() => {
      this.cd.detectChanges();
    }, 1000);
  }

  //End Modal Events

  //helpers
  redirectToEmployeeAbsences() {
    this.dialogRef.close();
    this.absenceService.navigateToAbsences(
      this.absence.empUnum,
      this.absence.firstName + ' ' + this.absence.lastName,
      this.absence.empId
    );
  }

  get datesArray() {
    return this.editAbsenceFormGroup.get('datesArray') as FormArray;
  }

  asFormGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  toHoursAndMinutes(totalMinutes: number) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
  }

  formatDateForComparison(date: Date): string {
    if (!date) {
      return null;
    }

    return this.datePipe.transform(date, 'MM/dd/yyyy');
  }

  massageDateValues(): boolean {
    const startDate = new Date(
      this.editAbsenceFormGroup.get('startDate').value
    );
    const endDate = new Date(this.editAbsenceFormGroup.get('endDate').value);
    const returnDate = this.editAbsenceFormGroup.get('dateOfReturn').value
      ? new Date(this.editAbsenceFormGroup.get('dateOfReturn').value)
      : null;

    if (
      isNaN(startDate.getTime()) ||
      isNaN(endDate.getTime()) ||
      (returnDate && isNaN(returnDate.getTime()))
    ) {
      this.editAbsenceFormGroup.setErrors({ invalidDate: true });
      return false;
    }

    this.editAbsenceFormGroup.patchValue(
      {
        startDate: startDate,
        endDate: endDate,
        dateOfReturn: returnDate,
      },
      { emitEvent: false }
    );

    return true;
  }
  //End helpers
}
