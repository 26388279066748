import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {
  CalendarOptions,
  EventClickArg,
  //defineFullCalendarElement,
  EventInput,
  PluginDef,
} from '@fullcalendar/core';

// NOTE: this import must be below @fullcalendar/web-component
import { Router } from '@angular/router';
import listPlugin from '@fullcalendar/list';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import { BehaviorSubject } from 'rxjs';
import { IncidentalAbsence, Session } from 'src/app/my-sedgwick';
import { EncryptionService } from 'src/app/my-sedgwick/services/encryption.service';
import { CalendarEvent } from 'src/app/shared/models/CalendarEvent';
import { StatusEvent } from 'src/app/shared/models/StatusEvent';
import { ClaimDataService } from 'src/app/shared/services/claim-data.service';
import { UserActionService } from 'src/app/shared/services/user-action.service';
import {
  ConfigurationService,
  InpageAliasService,
} from '../../../../my-sedgwick';

// make the <full-calendar> element globally available by calling this function at the top-level
// defineFullCalendarElement();

@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: false,
  // imports: [CommonModule],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
  template: `
    <div class="tw-max-w-screen-xl">
      <full-calendar
        #fullCalendar
        [options]="calendarOptions"
        (datesSet)="dateSetCallback($event)"
      ></full-calendar>
    </div>
    <ng-template #dialogEventPopup>
      <div class="tw-flex tw-flex-wrap">
        <app-dialog
          [title]="popupTitle"
          ariaLabel="Event dialog"
          [width]="700"
          [showActions]="false"
          [showHeader]="true"
          ariaLabelMessage="Close"
          [customeHeadingClass]="'calHeading'"
        >
          <div class="tw-w-full app-pb3 app-pt1">
            <ng-container *ngIf="IsAbsence">
              <table class="tw-w-full">
                <tr>
                  <td class="eventHeading" colspan="2">
                    <b *ngIf="!isIncidentalAbsence4Feature">Reason :</b>
                    <b *ngIf="isIncidentalAbsence4Feature">Category :</b>
                    {{
                      absenceDetail.absenceReason +
                        (showReasonSubtype
                          ? ' - ' + absenceDetail.typeOfCallOff
                          : '')
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="eventHeading" colspan="2">
                    <b>Start :</b
                    >{{
                      showTimeStamp
                        ? (absenceDetail.absenceStartDate | format : 'dateTime')
                        : (absenceDetail.absenceStartDate | format : 'date')
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="eventHeading" colspan="2">
                    <b>End :</b
                    >{{
                      showTimeStamp
                        ? (absenceDetail.absenceEndDate | format : 'dateTime')
                        : (absenceDetail.absenceEndDate | format : 'date')
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="eventHeading" colspan="2">
                    <b *ngIf="showTimeStamp">Return Date :</b>
                    <b *ngIf="!showTimeStamp">Return to Work Date :</b>
                    {{
                      showTimeStamp
                        ? (absenceDetail.estimatedRTWDate | format : 'dateTime')
                        : (absenceDetail.estimatedRTWDate | format : 'date')
                    }}
                  </td>
                </tr>
              </table>
            </ng-container>
            <ng-container *ngIf="!IsAbsence">
              <table class="tw-w-full">
                <tr>
                  <td class="eventHeading" colspan="2" *ngIf="ShowClaimNoLink">
                    <b>Claim Number : </b>
                    {{ eventData.data.claimDetails.claimNumber }}
                  </td>
                  <td class="eventHeading" colspan="2" *ngIf="!ShowClaimNoLink">
                    <b>Claim Number : </b>
                    <span routerLink="" href="" (click)="NavigateClaim()"
                      ><a>{{
                        eventData.data.claimDetails.claimNumber
                      }}</a></span
                    >
                  </td>
                </tr>
                <tr>
                  <td class="eventHeading" colspan="2">
                    <b>Claim Type : </b>
                    {{
                      eventData.data.claimDetails.lob === 'AD'
                        ? eventData.data.entry.claimDescription + ' Request'
                        : (eventData.data.entry.claimDescription | titlecase)
                    }}
                  </td>
                </tr>
                <tr *ngIf="eventData.data.claimDetails.lob !== 'WC'">
                  <td class="eventHeading" colspan="2">
                    <b
                      >{{
                        eventData.data.claimDetails.lob !== 'DS'
                          ? eventData.data.claimDetails.lob === 'AD'
                            ? 'Begin Date'
                            : eventData.data.entry.claimDescription.toLowerCase() ===
                              'reduced work schedule'
                            ? 'Leave Begin'
                            : 'Start Date'
                          : 'Benefits Begin'
                      }}:
                    </b>
                    <ng-container
                      *ngIf="
                        eventData.data.entry.claimDescription.toLowerCase() ===
                        'continuous leave'
                      "
                    >
                      {{
                        eventData.data.entry.continuesStartDate
                          | date : 'M/d/yyyy'
                      }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        eventData.data.entry.claimDescription.toLowerCase() ===
                        'intermittent leave'
                      "
                    >
                      {{
                        eventData.data.entry.absenceBeginDate
                          | date : 'M/d/yyyy'
                      }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        eventData.data.entry.claimDescription.toLowerCase() ===
                        'reduced work schedule'
                      "
                    >
                      {{
                        eventData.data.entry.absenceBeginDate
                          | date : 'M/d/yyyy'
                      }}
                    </ng-container>
                    <ng-container
                      *ngIf="eventData.data.claimDetails.lob !== 'LV'"
                    >
                      {{ eventData.data.entry.beginDate | date : 'M/d/yyyy' }}
                    </ng-container>
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.lob !== 'WC' &&
                    !eventData.data.entry.isEndDateEmpty
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b
                      >{{
                        eventData.data.claimDetails.lob !== 'DS'
                          ? eventData.data.entry.claimDescription.toLowerCase() ===
                            'reduced work schedule'
                            ? 'Leave End'
                            : 'End Date'
                          : 'Benefits End'
                      }}: </b
                    ><ng-container
                      *ngIf="
                        eventData.data.entry.claimDescription.toLowerCase() ===
                        'continuous leave'
                      "
                    >
                      {{
                        eventData.data.entry.continuesEndDate
                          | date : 'M/d/yyyy'
                      }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        eventData.data.entry.claimDescription.toLowerCase() ===
                        'intermittent leave'
                      "
                    >
                      {{
                        eventData.data.entry.absenceEndDate | date : 'M/d/yyyy'
                      }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        eventData.data.entry.claimDescription.toLowerCase() ===
                        'reduced work schedule'
                      "
                    >
                      {{
                        eventData.data.entry.absenceEndDate | date : 'M/d/yyyy'
                      }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        (eventData.data.claimDetails.lob === 'DS' ||
                          eventData.data.claimDetails.lob === 'AD') &&
                        !eventData.data.entry.isEndDateEmpty
                      "
                    >
                      {{ eventData.data.entry.endDate | date : 'M/d/yyyy' }}
                    </ng-container>
                  </td>
                </tr>
                <tr *ngIf="eventData.data.claimDetails.lob === 'WC'">
                  <td class="eventHeading" colspan="2">
                    <b>Benefit Status : </b>
                    {{ eventData.data.entry.benefitStatus }}
                  </td>
                </tr>
                <tr>
                  <td class="eventHeading" colspan="2">
                    <ng-container
                      *ngIf="
                        eventData.data.claimDetails.lob === 'DS' ||
                        eventData.data.claimDetails.lob === 'WC' ||
                        eventData.data.claimDetails.lob === 'AD'
                      "
                    >
                      <b>Status : </b
                      >{{
                        eventData.data.claimDetails.lob === 'WC'
                          ? getWCClaimStatus(eventData.data.entry)
                          : eventData.data.entry.subStatus !== ''
                          ? eventData.data.entry.status +
                            ' - ' +
                            eventData.data.entry.subStatus
                          : eventData.data.entry.status
                      }}
                    </ng-container>

                    <ng-container
                      *ngIf="
                        eventData.data.claimDetails.lob === 'LV' &&
                        eventData.data.claimDetails.source !== 'L'
                      "
                    >
                      <b>Leave Status : </b
                      >{{
                        eventData.data.entry.claimDescription.toLowerCase() !==
                        'reduced work schedule'
                          ? getStatusOthers(eventData.data.entry)
                          : getLeaveStatusReduceWS(eventData)
                      }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        eventData.data.claimDetails.lob === 'LV' &&
                        eventData.data.claimDetails.source === 'L'
                      "
                    >
                      <b>Leave Status : </b>{{ eventData.data.status }}
                    </ng-container>
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.lob === 'LV' &&
                    eventData.data.entry.claimDescription.toLowerCase() !==
                      'reduced work schedule'
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>Absence Status : </b
                    >{{ eventData.data.entry.absenceStatus }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.lob !== 'WC' &&
                    eventData.data.claimDetails.lob !== 'DS' &&
                    eventData.data.claimDetails.source !== 'L' &&
                    eventData.data.entry.claimDescription.toLowerCase() ===
                      'intermittent leave'
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>Date Absence Reported : </b
                    >{{
                      eventData.data.entry.absenceCallInDateTime
                        | date : 'M/d/yyyy'
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.lob !== 'WC' &&
                    eventData.data.claimDetails.lob !== 'DS' &&
                    eventData.data.claimDetails.source !== 'L' &&
                    eventData.data.entry.claimDescription.toLowerCase() ===
                      'intermittent leave'
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>Absence Reason : </b
                    >{{ eventData.data.entry.absenceReason ?? 'None' }}
                  </td>
                </tr>
                <tr *ngIf="eventData.data.entry.examiner">
                  <td class="eventHeading" colspan="2">
                    <b
                      >{{
                        alias.replaceAliases(
                          'examinerSingular',
                          claimConfigService.GetConfig.configFeatures.aliases
                        ) | titlecase
                      }}
                      : </b
                    >{{ removedots(eventData.data.entry.examiner) }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.lob === 'LV' &&
                    eventData.data.claimDetails.source !== 'L'
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>Leave Type : </b
                    >{{
                      eventData.data.claimDetails.lob !== 'WC'
                        ? eventData.data.entry.leaveType
                        : eventData.data.entry.claimDescription
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.source !== 'L' &&
                    eventData.data.claimDetails.lob === 'LV' &&
                    eventData.data.entry.claimDescription.toLowerCase() !==
                      'reduced work schedule'
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>Date Of Absence : </b
                    >{{ eventData.data.entry.absenceDate | date : 'M/d/yyyy' }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.lob === 'LV' &&
                    eventData.data.entry.leaveCausedBy
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>Leave Caused By : </b
                    >{{ eventData.data.entry.leaveCausedBy }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.lob === 'LV' &&
                    eventData.data.entry.exhaustionDate &&
                    eventData.data.entry.claimDescription.toLowerCase() !==
                      'reduced work schedule'
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>Exhaustion Date: </b
                    >{{
                      eventData.data.entry.exhaustionDate | date : 'M/d/yyyy'
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.lob === 'LV' &&
                    eventData.data.entry.actualReturnToWorkDate &&
                    eventData.data.entry.claimDescription.toLowerCase() !==
                      'reduced work schedule'
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>Actual Return To Work Date : </b
                    >{{
                      eventData.data.entry.actualReturnToWorkDate
                        | date : 'M/d/yyyy'
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.lob !== 'DS' &&
                    eventData.data.entry.relationship &&
                    eventData.data.entry.claimDescription.toLowerCase() !==
                      'reduced work schedule'
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>Relationship : </b
                    >{{ eventData.data.entry.relationship }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.lob === 'DS' &&
                    eventData.data.entry.lastMedicalReceived
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>Last Medical Received : </b
                    >{{
                      eventData.data.entry.lastMedicalReceived
                        | date : 'M/d/yyyy'
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.lob === 'WC' ||
                    eventData.data.claimDetails.lob === 'DS'
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>{{
                      eventData.data.claimDetails.lob === 'WC'
                        ? 'Date Of Injury :'
                        : 'First Absence :'
                    }}</b
                    >{{ eventData.data.entry.lossDate | date : 'M/d/yyyy' }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.lob === 'WC' ||
                    eventData.data.claimDetails.lob === 'DS'
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>Current Work Status: </b
                    >{{ eventData.data.entry.workStatus }}
                  </td>
                </tr>
                <tr *ngIf="eventData.data.claimDetails.lob === 'AD'">
                  <td class="eventHeading" colspan="2">
                    <b>Type: </b>{{ eventData.data.entry.accommodationType }}
                  </td>
                </tr>
                <tr *ngIf="eventData.data.claimDetails.lob === 'AD'">
                  <td class="eventHeading" colspan="2">
                    <b>Long Term: </b
                    >{{ eventData.data.entry.accommodationLongTerm }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.lob === 'WC' &&
                    eventData.data.entry.anticipatedReturnToWorkDuty
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>Anticipated Return To Work Duty: </b
                    >{{ eventData.data.entry.anticipatedReturnToWorkDuty }}
                  </td>
                </tr>
                <tr *ngIf="eventData.data.entry.anticipatedReturnToWorkDate">
                  <td class="eventHeading" colspan="2">
                    <b>Anticipated Return To Work Date: </b
                    >{{
                      eventData.data.entry.anticipatedReturnToWorkDate
                        | date : 'M/d/yyyy'
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.source !== 'L' &&
                    eventData.data.entry.claimDescription.toLowerCase() ===
                      'intermittent leave'
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>Duration of Absence : </b
                    >{{ eventData.data.entry.hoursMissed }} hrs
                    {{ eventData.data.entry.minutesMissed }} minutes
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.source !== 'L' &&
                    eventData.data.entry.claimDescription.toLowerCase() ===
                      'continuous leave'
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>Duration of Absence : </b
                    >{{ eventData.data.entry.absenceDuration }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    eventData.data.claimDetails.source !== 'L' &&
                    eventData.data.entry.claimDescription.toLowerCase() ===
                      'intermittent leave'
                  "
                >
                  <td class="eventHeading" colspan="2">
                    <b>Absence Confirmation Number : </b
                    >{{ eventData.data.entry.absenceConfirmationNumber }}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td class="tw-flex tw-justify-end">
                    <app-button
                      right1
                      emphasis="High"
                      (click)="closeModalPopUp()"
                      >Close</app-button
                    >
                  </td>
                </tr>
              </table>
            </ng-container>
          </div>
        </app-dialog>
        <!-- </app-panel> -->
      </div>
    </ng-template>
  `,
  styles: [
    `
      ::ng-deep body .fc-toolbar-title {
        font-size: 0.8em;
      }
      @media screen and (max-width: 700px) {
        ::ng-deep .fc-toolbar.fc-header-toolbar {
          font-size: 51%;
        }

        .claimNumberbold {
          font-size: 12px;
          font-weight: bold;
        }
      }
      .colorswblue {
        color: var(--color-sedgwick-blue) !important;
      }

      .dialog-title {
        color: black;
        font-weight: bold;
        font-size: 18px;
        padding-right: 18px;
      }

      .dialog-x {
        float: right;
        cursor: pointer;
        color: black;
        font-weight: bold;
        font-size: 18px;
        padding-left: 48px;
      }
      ::ng-deep .fc-event {
        white-space: normal !important;
        overflow: hidden;
        text-overflow: ellipsis;
        height: auto !important;
        line-height: 1.2 !important;
        padding: 2px 4px !important;
      }
      ::ng-deep .fc-event-title {
        white-space: normal !important;
        word-wrap: break-word !important;
      }
    `,
  ],
})
export class CalendarComponent implements OnInit, AfterViewInit, OnChanges {
  colorIndex = 0;

  @Input()
  dark = false;

  popupTitle: string;

  @Input()
  IsAbsence: boolean = true;

  @Input()
  ShowClaimNoLink: boolean = false;
  lightColors = [
    '#ce93d8',
    '#b39ddb',
    '#9fa8da',
    '#90caf9',
    '#81d4fa',
    '#80deea',
    '#80cbc4',
    '#a5d6a7',
    '#c5e1a5',
  ];

  darkColors = [
    '#7b1fa2',
    '#512da8',
    '#303f9f',
    '#1976d2',
    '#0288d1',
    '#0097a7',
    '#00796b',
    '#388e3c',
    '#689f38',
  ];

  calendarOptions: CalendarOptions;
  dateSetCallback(event: any) {
    //console.log('CALENDAR DATE SET!!!!!!!!!!!!!', event);
    this.cdr.detectChanges();

    this.calendarDateUpdated.next(event);
    this.cdr.detectChanges();
    this.render();
  }

  @Output()
  calendarDateUpdated: BehaviorSubject<any> = new BehaviorSubject<any>({}); // (v: any) => {};

  absenceDetail: IncidentalAbsence;

  eventData: any;

  @Input()
  toolbarLeft: string = 'prev,next today';

  @Input()
  toolbarRight: string = 'dayGridMonth,dayGridWeek,dayGridDay,listDay';

  @Input()
  events: CalendarEvent[];

  @Input()
  IsListViewShow: boolean = true;

  showTimeStamp: boolean = true;

  showReasonSubtype: boolean = true;
  isIncidentalAbsence4Feature: boolean = false;

  @ViewChild('fullCalendar', { static: true })
  fullCalendar: ElementRef;

  @ViewChild('dialogEventPopup') dialogEvnetpopup: TemplateRef<any>;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public alias: InpageAliasService,
    public claimConfigService: ConfigurationService,
    public claimDataService: ClaimDataService,
    private cdr: ChangeDetectorRef,
    private userAction: UserActionService,
    private session: Session,
    private encrypt: EncryptionService
  ) {
    //customizations for nat grid
    this.showTimeStamp = this.showReasonSubtype =
      !this.claimConfigService.getConfigFeatureAccess('IncidentalAbsence');
  }

  async ngOnInit() {
    this.events = [...this.events];
    this.isIncidentalAbsence4Feature =
      await this.claimConfigService.getConfigFeatureAccess('IncidentalAbsence');
    this.showTimeStamp = this.showReasonSubtype =
      !this.isIncidentalAbsence4Feature;
    //console.log(this.events);
    this.render();
  }

  ngOnChanges() {
    // console.log('it changed', this.events);
    this.render();
  }

  ngAfterViewInit() {
    this.wcagCompliance();
  }

  update() {
    if (this.fullCalendar?.nativeElement) {
      this.fullCalendar.nativeElement._calendar.updateSize();
      this.fullCalendar.nativeElement._calendar.render();
      this.wcagCompliance();
    }
  }

  getLeaveStatus(data: CalendarEvent) {
    const leaveStatus: string = data.data.entry.status;
    return leaveStatus;
  }

  getLeaveStatusReduceWS(data: CalendarEvent) {
    let leaveStatus: string = data.data.entry.status + ' - ';
    if (
      data.data.entry.absenceReason != undefined &&
      data.data.entry.absenceReason != null &&
      data.data.entry.absenceReason.trim().length > 2
    ) {
      leaveStatus = leaveStatus + data.data.entry.absenceReason;
    } else {
      leaveStatus = leaveStatus + ' None';
    }

    return leaveStatus;
  }

  mockData() {
    const colors = this.dark ? this.darkColors : this.lightColors;
    const textColor = this.dark ? 'white' : 'black';
    const events: EventInput[] = [];
    for (let i = 0; i < 5 && this.mockData; i++) {
      const x1 = Math.floor(Math.random() * 28);
      const x2 = Math.floor(Math.random() * 28);
      const y = new Date().getFullYear();
      const m = new Date().getMonth();
      const start = new Date(y, m, Math.min(x1, x2));
      const end = new Date(y, m, Math.max(x1, x2));
      const color = this.nextColor(colors);
      events.push({
        title: `event ${i + 1}`,
        start,
        end,
        backgroundColor: color,
        borderColor: color,
        textColor: textColor,
        allDay: true,
      });
    }
  }

  render() {
    const colors = this.dark ? this.darkColors : this.lightColors;
    this.shuffle(colors);
    //console.log('CALENDAR RENDER EVENTS', this.events);
    //debugger;
    const eventInputs: EventInput[] = this.events?.map((ce) => {
      if (new Date(ce.start) > new Date('12/31/2024')) {
        //debugger;
      }
      const ei = <EventInput>ce;
      if (!ei.backgroundColor) {
        ei.backgroundColor = this.nextColor(colors);
        ei.borderColor = ei.backgroundColor;
        ei.textColor = this.dark ? 'white' : 'black';
      }

      return ei;
    });
    const pluginslst: PluginDef[] = [resourceDayGridPlugin];

    if (this.IsListViewShow) {
      pluginslst.push(listPlugin);
    }

    this.calendarOptions = {
      plugins: pluginslst,
      aspectRatio: 1,
      events: eventInputs,
      navLinks: false,
      editable: true,
      headerToolbar: {
        left: this.toolbarLeft,
        center: 'title',
        right: this.toolbarRight,
      },
      height: 'auto',
      contentHeight: 'auto',
      schedulerLicenseKey: this.session.user.schedulerKey,
      datesSet: (dateinfo: any) => this.dateSetCallback(dateinfo),

      eventClick: (args: EventClickArg) => {
        if (this.IsAbsence) {
          this.popupTitle =
            args.event.extendedProps['firstName'] +
            ' ' +
            args.event.extendedProps['lastName'] +
            ' - ' +
            args.event.extendedProps['absenceReason'];
          this.dialog.open(this.dialogEvnetpopup);
          this.absenceDetail = new IncidentalAbsence();
          this.absenceDetail.absenceReason =
            args.event.extendedProps['absenceReason'];
          this.absenceDetail.absenceNumber =
            args.event.extendedProps['absenceNumber'];
          this.absenceDetail.absenceStartDate =
            args.event.extendedProps['absenceStartDate'];
          this.absenceDetail.absenceEndDate =
            args.event.extendedProps['absenceEndDate'];
          this.absenceDetail.estimatedRTWDate =
            args.event.extendedProps['estimatedRTWDate'];
          this.absenceDetail.typeOfCallOff =
            args.event.extendedProps['typeOfCallOff'];
        } else {
          this.popupTitle = 'Event';
          this.dialog.open(this.dialogEvnetpopup);
          this.eventData = args.event.extendedProps;
        }
      },
      //WCAG compliance custom code
      eventContent: (arg) => {
        return {
          html: `
          <span class="fc-event-title">${arg.event.title}</span>
          <span class='sr-only'>From ${arg.event.start?.toDateString()} to ${arg.event.end?.toDateString()} </span>
          `,
        };
      },
      dayCellDidMount: (arg) => {
        const anchor = arg.el.querySelector('a');
        if (anchor) {
          anchor.setAttribute('role', 'presentation');
          const span = document.createElement('span');
          span.innerHTML = anchor.innerHTML;
          span.setAttribute('aria-label', arg.date.toDateString());
          span.classList.add('sr-only');
          anchor.appendChild(span);
          anchor.removeAttribute('aria-pressed');
        }
      },
    };
  }

  private shuffle(array: string[]) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  private nextColor(colors: string[]): string {
    if (this.colorIndex === colors.length - 1) {
      this.colorIndex = 0;
    }

    return colors[this.colorIndex++];
  }

  closeModalPopUp() {
    this.dialog.closeAll();
  }
  async NavigateClaim() {
    //   let myClaim: MyClaim;

    //   // eslint-disable-next-line prefer-const
    //   myClaim = { ...myClaim, ...this.eventData.data.claimDetails };
    //   await this.claimDataService.setMyClaim(myClaim);
    //   this.userAction.setClaimant(this.eventData.data.entry);
    //   if (myClaim.userOwns === undefined) {
    //     myClaim.userOwns = this.session.user.empUnum === myClaim.empUnum;
    //   }
    //   if (myClaim.userOwns) {
    //     this.userAction.setAction(UserActions.ViewOwnClaim);
    //   } else {
    //     this.userAction.setAction(UserActions.MgrViewEmpClaim);
    //   }
    const encryptedEmpUnum = this.encrypt.encrypt(
      this.eventData.data.entry.empUnum
    );
    this.router.navigate(['claims'], {
      queryParams: {
        claimId: this.eventData.data.entry.claimId,
        source: this.eventData.data.entry.source,
        lob: this.eventData.data.entry.lob,
        emp: !this.eventData.data.entry.userOwns ? encryptedEmpUnum : null,
      },
    });
    this.closeModalPopUp();
  }

  getStatusFromStatusCode(entry: StatusEvent) {
    if (entry.statusCode == 'OA') {
      return 'Open - Approved';
    } else if (entry.statusCode == 'OP') {
      return 'Open - Pending';
    } else if (entry.statusCode == 'DP') {
      return 'Denied - Pending';
    } else if (entry.statusCode == 'DA') {
      return 'Denied - Approved';
    } else if (entry.statusCode == 'OD') {
      return 'Denied';
    } else if (entry.statusCode == 'WP') {
      return 'Waiting Period';
    } else {
      return entry.status;
    }
  }

  getStatusOthers(entry: StatusEvent) {
    if (entry.lob.toLowerCase() != 'lv') {
      if (entry.statusCode) {
        return this.getStatusFromStatusCode(entry);
      } else {
        return entry.status;
      }
    } else if (
      entry.source === 'L' &&
      (entry.absenceStatus.toLowerCase() == 'pending' ||
        entry.absenceStatus.toLowerCase() == 'denied' ||
        entry.absenceStatus.toLowerCase() == 'waiting period')
    ) {
      return this.getStatusWithoutSubStatus(entry.status) + entry.absenceStatus;
    } else {
      if (entry.statusCode) {
        return this.getStatusFromStatusCode(entry);
      } else {
        return entry.status;
      }
    }
  }

  getStatusWithoutSubStatus(status: string) {
    if (status != undefined && status.length > 3 && status.indexOf('-') > -1) {
      return status.split('-')[0] + ' - ';
    } else {
      return '';
    }
  }

  getStatusOthersWithSubStatus(entry: StatusEvent) {
    if (entry.statusCode) {
      return this.getStatusFromStatusCode(entry);
    } else if (entry.subStatus) {
      return entry.status + '-' + entry.subStatus;
    } else {
      return entry.status;
    }
  }

  getWCClaimStatus(entry: StatusEvent) {
    if (entry.statusCode) {
      if (entry.statusCode == 'OA') {
        return 'Open - Approved';
      } else if (entry.statusCode == 'OP') {
        return 'Open - Pending';
      } else if (entry.statusCode == 'WP' || entry.statusCode == 'WA') {
        return 'Waiting';
      } else if (
        entry.statusCode == 'DP' ||
        entry.statusCode == 'DA' ||
        entry.statusCode == 'OD'
      ) {
        return 'Denied';
      }
    }

    if (entry.subStatus != undefined && entry.subStatus.length > 2) {
      return entry.status + ' - ' + entry.subStatus;
    } else {
      return entry.status;
    }
  }

  removedots(examinerName: string) {
    if (examinerName != undefined) {
      const dotIndex = examinerName.lastIndexOf('.');
      if (dotIndex == examinerName.length - 1) {
        return examinerName.substring(0, dotIndex);
      }
    }
    return examinerName;
  }

  wcagCompliance() {
    const buttons = document.querySelectorAll('button[aria-pressed]');
    if (buttons.length > 0) {
      buttons.forEach((button) => {
        button.removeAttribute('aria-pressed');
        button.setAttribute('aria-label', button.getAttribute('title') || '');
        button.removeAttribute('title');
      });
    }
  }
}

/*export class CalendarEvent {
  start: Date;
  end: Date;
  display?: string;
  title?: string;
  backgroundColor?: string;
  color?: string;
  allDay?: boolean;
}*/
