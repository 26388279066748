import { Component, Input } from '@angular/core';
import { IncidentalAbsence, IncidentalAbsenceReason } from '../..';
import { AbsencePageComponent } from '../absence/absence-page.component';
import { AbsenceGridComponent } from './absence-grid.component';

@Component({
  selector: 'app-my-absence-grid',
  template: `
    <app-absence-grid
      class="tw-w-full"
      [absences]="myAbsenceData"
      [absencesLoading]="false"
      [reasonData]="reasonData"
      [employeeLinkEnabled]="false"
      [filterEnabled]="true"
      [ownAbsence]="true"
      noDataMessage="There are no absences."
      [hideRef]="true"
    ></app-absence-grid>
  `,
  styles: [],
})
export class MyAbsenceGridComponent {
  @Input()
  myAbsenceData: IncidentalAbsence[] = [];

  @Input()
  reasonData: IncidentalAbsenceReason[];
}
