import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { isArray } from 'lodash';
import { ConfigurationService, Session } from 'src/app/my-sedgwick';
import {
  BaseComponent,
  ListComponent,
  SearchResult,
  SnackbarService,
  SnackbarType,
  getUrlParams,
  ValidatorsEx,
  srSpeak,
} from 'src/app/shared';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';
import {
  AbsenceInfo,
  AbsenceReport,
  ReportIntermittentAbsenceResponse,
} from '../../models/Absence-Report';
import { ClaimService } from '../../services/claim.service';
import { ReportAbsenceService } from '../../services/report-absence.service';

@Component({
  selector: 'app-report-an-absence-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <!-- <ng-container *ngIf="isBusy">
      <mat-spinner
        color="accent"
        diameter="40"
        mode="indeterminate"
      ></mat-spinner>
    </ng-container> -->
    <app-section class="tw-w-full">
      <div class="app-pl2 tw-w-full">
        <app-claim-breadcrumb></app-claim-breadcrumb>
      </div>
      <app-panel
        [largeHeader]="true"
        title="Report an Absence"
        class="tw-w-full"
        [showTitle]="true"
        [canExpand]="false"
      >
        <app-claim-header
          class="tw-w-full"
          [summary]="summary"
        ></app-claim-header>

        <div *ngIf="submitted === false">
          <form
            [formGroup]="newAbsenceFormGroup"
            (ngSubmit)="(null)"
            class="tw-flex tw-flex-wrap tw-w-full"
          >
            <app-http-error
              class="tw-w-full"
              [httpErrorResponse]="null"
            ></app-http-error>
            <div class="tw-w-full app-pb1">
              <span>{{ clientMessage }}</span>
            </div>
            <div class="tw-w-full app-pb1">
              <label><strong>DATE OF ABSENCE</strong></label>
            </div>
            <div class="tw-w-full">
              <app-date-control
                formControlName="dateOfAbsence"
                [formGroup]="newAbsenceFormGroup"
                label="DATE OF ABSENCE"
                validationControl="Date of absence"
                ngDefaultControl
                class="tw-w-full md:tw-w-[35%]"
              ></app-date-control>
            </div>
            <div class="tw-w-full app-pb1">
              <span
                ><strong>DURATION: </strong>{{ clientDurationHours }} hours
                {{ clientDurationMinutes }} minutes per day maximun</span
              ><br />
              <span
                >Please report in increments of
                {{ clientMinutesIncrement }} minutes.</span
              >
            </div>
            <div>
              <div class="tw-w-[80%] md:tw-w-full app-mb4">
                <app-text-control
                  formControlName="hours"
                  [formGroup]="newAbsenceFormGroup"
                  label="Hours"
                  validationControl="Hours"
                  type="number"
                  ngDefaultControl
                ></app-text-control>
              </div>
            </div>

            <div>
              <div class="tw-w-[80%] md:tw-w-full app-mb4">
                <app-text-control
                  formControlName="minutes"
                  [formGroup]="newAbsenceFormGroup"
                  label="Minutes"
                  validationControl="Minutes"
                  type="number"
                  ngDefaultControl
                ></app-text-control>
              </div>
            </div>

            <div class="tw-w-full">
              <div class="tw-w-full md:tw-w-[50%]">
                <app-container-control
                  ngDefaultControl
                  formControlName="reason"
                  [formGroup]="newAbsenceFormGroup"
                  label="REASON*"
                  validationControl="Reason"
                  [isLabelBold]="true"
                >
                  <mat-radio-group
                    aria-label="Type of Reason"
                    role="radiogroup"
                  >
                    <mat-radio-button
                      value="ILLNESS"
                      [checked]="newAbsenceReason === 'ILLNESS'"
                      >Illness</mat-radio-button
                    >
                    <span class="app-pl2"></span>
                    <mat-radio-button
                      value="APPOINTMENT"
                      [checked]="newAbsenceReason === 'APPOINTMENT'"
                      >Appointment</mat-radio-button
                    >
                  </mat-radio-group>
                </app-container-control>
              </div>
            </div>
          </form>

          <div class="tw-w-full app-pt4">
            <app-action-row class="tw-w-full">
              <app-button left1 emphasis="High" (click)="addAbsence()"
                >Add absence</app-button
              >
            </app-action-row>
          </div>
          <br />
          <div *ngIf="invalidSubmit === true" class="app-pt2 tw-w-[65%]">
            <app-toast type="Error">
              Please review errors to add a new request.
            </app-toast>
          </div>
        </div>

        <div *ngIf="submitted" class="app-pt2 tw-w-[65%]">
          <app-toast type="Info">
            Thank you for submitting your intermittent absences for review.<br />
            Please note the confirmation number does not indicate the absence is
            approved.<br />
            To check on an absence's status, visit the Off Work Calendar.
          </app-toast>
        </div>

        <div class="tw-w-full">
          <div>
            <app-list
              id="test"
              class="tw-w-full"
              [template]="row"
              [dataSource]="absences"
              [includeNoDataIcon]="true"
              [noDataIcon]="noDataIcon"
              [deleteEnabled]="true"
              [noDataMessage]="noDataMessage"
              [noDataThinMessage]="noDataThinMessage"
              #requestList
            >
              <ng-container header>
                <div class="tw-flex tw-flex-wrap tw-w-full">
                  <app-list-header
                    name="date"
                    label="Date"
                    class="tw-w-full md:tw-w-[20%]"
                  ></app-list-header>

                  <app-list-header
                    name="duration"
                    label="Duration"
                    class="tw-w-full md:tw-w-[30%]"
                  ></app-list-header>

                  <app-list-header
                    name="reason"
                    label="Reason"
                    class="tw-w-full md:tw-w-[35%]"
                  ></app-list-header>

                  <app-list-header
                    *ngIf="submitted === false"
                    name="actions"
                    label="Actions"
                    class="tw-w-full md:tw-w-[15%]"
                  ></app-list-header>

                  <app-list-header
                    *ngIf="submitted === true"
                    name="confirmation"
                    label="Confirmation"
                    class="tw-w-full md:tw-w-[15%]"
                  ></app-list-header>
                </div>
              </ng-container>

              <ng-template #row let-element>
                <div
                  class="app-pt2 app-pb2 tw-flex tw-flex-wrap tw-w-full tw-items-center"
                >
                  <app-value
                    class="tw-w-full md:tw-w-[20%]"
                    label="Date of Absence"
                    [value]="element.dateOfAbsence | format : 'date'"
                    layout="row-xs"
                  ></app-value>
                  <app-value
                    class="tw-w-full md:tw-w-[30%]"
                    label="Duration"
                    [value]="element.result"
                    layout="row-xs"
                  ></app-value>
                  <app-value
                    class="tw-w-full md:tw-w-[35%]"
                    label="Reason"
                    [value]="element.reason"
                    layout="row-xs"
                  ></app-value>
                  <div *ngIf="submitted === false" class="editActions">
                    <div class="tw-hidden : md:tw-block">
                      <app-icon-button
                        value
                        mat-icon-button
                        tabindex="0"
                        class="app-pr2"
                        role="button"
                        icon="edit"
                        label="Edit"
                        (click)="editAbsenceDialog(element)"
                        (keypress.enter)="editAbsenceDialog(element)"
                        [attr.aria-label]="
                          'Edit Absence for claim - ' +
                          element.claimDescription +
                          ' - ' +
                          element.claimNumber
                        "
                      ></app-icon-button>
                      <app-icon-button
                        value
                        tabindex="0"
                        role="button"
                        mat-icon-button
                        color="accent"
                        icon="delete"
                        label="Delete"
                        [attr.aria-label]="
                          'Delete Absence for claim - ' +
                          element.claimDescription +
                          ' - ' +
                          element.claimNumber
                        "
                        (click)="deleteAbsenceDialog(element)"
                        (keydown.enter)="deleteAbsenceDialog(element)"
                      ></app-icon-button>
                    </div>
                    <div class="tw-block md:tw-hidden">
                      <app-value class="tw-w-full" layout="row-xs">
                        <app-icon-button
                          value
                          mat-icon-button
                          [tabindex]="0"
                          icon="more_vert"
                          label="Actions"
                          [ariaLabelText]="
                            'Actions menu for modifying absences'
                          "
                          [attr.aria-label]="
                            'Actions menu for modifying absences'
                          "
                          [matMenuTriggerFor]="actionMenu"
                          role="button"
                          (click)="toggleMenu()"
                          (keydown.enter)="toggleMenu()"
                          [attr.aria-expanded]="isActionsMenuExpanded"
                        >
                          <mat-menu
                            #actionMenu="matMenu"
                            role="menu"
                            (closed)="toggleMenu()"
                          >
                            <h3 class="tw-w-full app-pl2 action-header">
                              Actions
                            </h3>
                            <button
                              mat-menu-item
                              (click)="editAbsenceDialog(element)"
                              (keypress.enter)="editAbsenceDialog(element)"
                              [attr.aria-label]="
                                'Edit Absence for claim - ' +
                                element.claimDescription +
                                ' - ' +
                                element.claimNumber
                              "
                            >
                              <span class="action-links">Edit absence</span>
                            </button>
                            <button
                              mat-menu-item
                              (click)="deleteAbsenceDialog(element)"
                              (keydown.enter)="deleteAbsenceDialog(element)"
                              [attr.aria-label]="
                                'Delete absence for claim - ' +
                                element.claimDescription +
                                ' - ' +
                                element.claimNumber
                              "
                            >
                              <span class="action-links">Delete absence</span>
                            </button>
                          </mat-menu>
                        </app-icon-button>
                      </app-value>
                    </div>
                  </div>
                  <div *ngIf="submitted === true">
                    <app-value
                      class="tw-w-full md:tw-w-[35%]"
                      label="Confirmation"
                      [value]="element.confirmationNumber"
                      layout="row-xs"
                    ></app-value>
                  </div>
                </div>
              </ng-template>
            </app-list>
          </div>
        </div>
        <ng-template #dialogEditAbsence>
          <app-dialog
            title="Edit Request"
            ariaLabel="Edit absence request dialog"
            [width]="500"
            [showActions]="true"
            (afterClosed)="cancelEdit()"
            [showConfirm]="true"
            [showCancel]="true"
            (confirmEvent)="saveEdit()"
            ariaLabelMessage="Close edit popup"
          >
            <form
              [formGroup]="editAbsenceFormGroup"
              (ngSubmit)="(null)"
              class="tw-flex tw-flex-wrap tw-w-full"
            >
              <!-- <div class="tw-w-full">
                <div class="app-header-2 app-pt1 app-pb1">Edit Request</div>
              </div> -->
              <app-http-error
                class="tw-w-full"
                [httpErrorResponse]="null"
              ></app-http-error>
              <div class="tw-w-full app-pt1 app-pb1">
                <span>{{ clientMessage }}</span>
              </div>
              <div class="tw-w-full app-pb1">
                <label><strong>DATE OF ABSENCE</strong></label>
              </div>

              <div class="tw-w-full">
                <app-date-control
                  formControlName="dateOfAbsence"
                  [formGroup]="editAbsenceFormGroup"
                  label="DATE OF ABSENCE"
                  ngDefaultControl
                  class="tw-w-full md:tw-w-[75%]"
                ></app-date-control>
              </div>

              <div class="tw-w-full app-pb1">
                <span
                  ><strong>DURATION: </strong>{{ clientDurationHours }} hours
                  {{ clientDurationMinutes }} minutes per day maximun</span
                ><br />
                <span
                  >Please report in increments of
                  {{ clientMinutesIncrement }} minutes.</span
                >
              </div>
              <div>
                <div class="tw-w-full md:tw-w-[80%] app-mb4">
                  <app-text-control
                    formControlName="hours"
                    [formGroup]="editAbsenceFormGroup"
                    label="Hours"
                    type="number"
                    ngDefaultControl
                  ></app-text-control>
                </div>
              </div>

              <div>
                <div class="tw-w-full md:tw-w-[80%] app-mb4">
                  <app-text-control
                    formControlName="minutes"
                    [formGroup]="editAbsenceFormGroup"
                    label="Minutes"
                    type="number"
                    ngDefaultControl
                  ></app-text-control>
                </div>
              </div>

              <div class="tw-w-full">
                <div class="tw-w-full md:tw-w-[50%]">
                  <app-container-control
                    ngDefaultControl
                    formControlName="reason"
                    [formGroup]="editAbsenceFormGroup"
                    label="REASON*"
                    [isLabelBold]="true"
                  >
                    <mat-radio-group
                      aria-label="Type of reason"
                      role="radiogroup"
                    >
                      <mat-radio-button
                        value="ILLNESS"
                        [checked]="editAbsenceReason === 'ILLNESS'"
                        >Illness</mat-radio-button
                      >
                      <span class="app-pl2"></span>
                      <mat-radio-button
                        value="APPOINTMENT"
                        [checked]="editAbsenceReason === 'APPOINTMENT'"
                        >Appointment</mat-radio-button
                      >
                    </mat-radio-group>
                  </app-container-control>
                </div>
              </div>
            </form>
            <div *ngIf="invalidEdit === true" class="app-pt2 tw-w-full">
              <app-toast type="Error">
                Please review errors to add a new request.
              </app-toast>
            </div>
          </app-dialog>
        </ng-template>
        <ng-template #dialogDeleteAbsence>
          <app-dialog
            title="Remove Request"
            ariaLabel="Remove absence request dialog"
            [width]="700"
            [showActions]="true"
            [showConfirm]="true"
            [showCancel]="true"
            (confirmEvent)="removeRequest()"
            ariaLabelMessage="Close edit popup"
          >
            <!-- <div class="tw-w-full">
              <div class="app-header-2 app-pt1 app-pb1">Remove Request</div>
            </div> -->
            <div class="tw-w-full app-pt1 app-pb1">
              <span>Press confirm to delete this absence request.</span>
            </div>
          </app-dialog>
        </ng-template>
        <app-action-row *ngIf="submitted === false" class="tw-w-full">
          <app-button
            *ngIf="absences.length > 0"
            right2
            emphasis="High"
            (click)="submitDirect()"
            [loading]="isBusy"
            >Submit</app-button
          >
          <app-button
            right1
            emphasis="Low"
            routerLink="/"
            [queryParams]="{
              claimId: claimId,
              source: source
            }"
            >Cancel</app-button
          >
        </app-action-row>
        <app-action-row *ngIf="submitted === true" class="tw-w-full">
          <app-button
            right2
            emphasis="High"
            routerLink="/"
            [queryParams]="{
              claimId: claimId,
              source: source
            }"
            >Close</app-button
          >
        </app-action-row>
      </app-panel>
    </app-section>`,
  styles: [
    `
      .editActions {
        color: var(--color-sedgwick-blue) !important;
      }
      .action-links {
        color: #007dbc;
        text-decoration: none;
        font-weight: bold;
      }
      .action-header {
        font-weight: bold;
      }
    `,
  ],
})
export class ReportAnAbsencePageComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, AfterContentChecked
{
  @ViewChild('dialogEditAbsence') dialogRefEditAbsence: TemplateRef<any>;
  @ViewChild('dialogDeleteAbsence') dialogRefDeleteAbsence: TemplateRef<any>;
  @ViewChild('requestList', { static: false }) requestList: ListComponent;

  claimId: string;
  source: string;
  clientMessage: string = '';
  clientDurationHours: number = 24;
  clientDurationMinutes: number = 60;
  clientMinutesIncrement: number = 1;
  submitted: boolean = false;
  invalidSubmit: boolean = false;
  invalidEdit: boolean = false;
  summary: SearchResult = <SearchResult>{};
  result: ReportIntermittentAbsenceResponse =
    new ReportIntermittentAbsenceResponse();
  absenceReport: AbsenceReport = new AbsenceReport();
  absenceToRemove: number;
  absences: AbsenceInfo[] = [];
  isBusy: boolean = false;

  noDataMessage: string = 'There are no new requests.';
  noDataThinMessage: string = 'Use the form above to add absences.';
  noDataIcon: string = 'calendar_today';
  isActionsMenuExpanded: boolean = false;

  newAbsenceFormGroup = this.formBuilder.group(
    {
      dateOfAbsence: [null as Date | null, [Validators.required]],
      hours: [null, []],
      minutes: [null, []],
      reason: ['', [customvalidations.required]],
    },
    {
      validator: this.validateHoursAndMinutes,
    }
  );

  editAbsenceFormGroup = this.formBuilder.group(
    {
      dateOfAbsence: [null as Date | null, [Validators.required]],
      hours: [null, []],
      minutes: [null, []],
      reason: ['', [customvalidations.required]],
    },
    {
      validator: this.validateHoursAndMinutes,
    }
  );

  get newAbsenceReason() {
    return this.newAbsenceFormGroup.get('reason').value;
  }

  get editAbsenceReason() {
    return this.editAbsenceFormGroup.get('reason').value;
  }

  constructor(
    private claimApi: ClaimService,
    private reportAbsenceService: ReportAbsenceService,
    private snackBarService: SnackbarService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public session: Session,
    private config: ConfigurationService
  ) {
    super();
  }

  deleteAbsenceDialog(element: AbsenceInfo) {
    this.absenceToRemove = this.absences.indexOf(element);
    this.dialog.open(this.dialogRefDeleteAbsence);
  }

  editAbsenceDialog(element: AbsenceInfo) {
    this.absenceToRemove = this.absences.indexOf(element);

    this.dialog.open(this.dialogRefEditAbsence, {
      data: this.editAbsenceFormGroup,
    });

    this.editAbsenceFormGroup.patchValue({
      dateOfAbsence: new Date(element.dateOfAbsence),
      hours: element.hours,
      minutes: element.minutes,
      reason: element.reason,
    });

    this.dialog.open(this.dialogRefEditAbsence);
  }

  validateHoursAndMinutes(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const hours = control.get('hours').value;
    const minutes = control.get('minutes').value;
    if (hours === null && minutes === null) {
      return null;
    }
    if ((hours === 0 || hours === '0') && (minutes === 0 || minutes === '0')) {
      control.get('minutes').setValue('');
      control.updateValueAndValidity();
      return { Invalid: true };
    }

    return null;
  }

  saveEdit(): void {
    if (!this.editAbsenceFormGroup.get('reason').valid) {
      this.editAbsenceFormGroup.get('reason').markAsTouched();
    }
    if (this.editAbsenceFormGroup.invalid) {
      this.invalidEdit = true;
      return;
    } else {
      this.invalidEdit = false;
    }

    const tempAbsence = this.absences[this.absenceToRemove];
    tempAbsence.dateOfAbsence =
      this.editAbsenceFormGroup.get('dateOfAbsence').value;
    tempAbsence.hours = this.editAbsenceFormGroup.get('hours').value;
    tempAbsence.minutes = this.editAbsenceFormGroup.get('minutes').value;
    tempAbsence.reason = this.editAbsenceFormGroup.get('reason').value;
    this.dialog.closeAll();
    this.requestList.updateDataSource();
    this.cdRef.detectChanges();
  }

  cancelEdit() {
    this.dialog.closeAll();
    this.editAbsenceFormGroup.reset();
  }

  cancelRemove() {
    this.dialog.closeAll();
  }

  removeRequest() {
    if (this.absenceToRemove > -1) {
      this.absences.splice(this.absenceToRemove, 1);
    }
    this.dialog.closeAll();
    this.requestList.updateDataSource();
    this.cdRef.detectChanges();
  }

  async submitDirect() {
    this.isBusy = true;
    this.absenceReport.claimId = this.summary.claimNumber;
    this.absenceReport.source = this.source;
    this.absenceReport.absences = this.absences;
    if (
      this.session.user.emulatorContext &&
      this.session.user.emulatorContext.isEmulating
    ) {
      this.snackBarService.show(
        'Preview-only mode. Information not sent.',
        SnackbarType.Info
      );
    } else {
      await this.reportAbsenceService
        .reportAbsenceAsync(this.absenceReport, this.session.user.employeeId)
        .then((response) => {
          if (isArray(response)) {
            if (response.length == this.absences.length) {
              for (let i = 0; i < response.length; i++) {
                if (response[i].success) {
                  this.absences[i].confirmationNumber =
                    response[i].confirmationId.toString();
                }
              }
            }
          }
          // if (response.success) {
          //   this.absences.forEach(
          //     (x) => (x.confirmationNumber = response.confirmationId.toString())
          //   );
          // }
        })
        .catch((error) => console.dir(error))
        .finally(() => {
          this.requestList.updateDataSource();
          this.cdRef.detectChanges();
          this.submitted = true;
          this.isBusy = false;
          this.cdRef.detectChanges();
        });
    }
  }

  addAbsence() {
    if (!this.newAbsenceFormGroup.get('reason').valid) {
      this.newAbsenceFormGroup.get('reason').markAsTouched();
    }
    if (!this.newAbsenceFormGroup.get('dateOfAbsence').valid) {
      this.newAbsenceFormGroup.get('dateOfAbsence').markAsTouched();
    }
    if (!this.newAbsenceFormGroup.get('hours').valid) {
      this.newAbsenceFormGroup.get('hours').markAsTouched();
    }
    if (!this.newAbsenceFormGroup.get('minutes').valid) {
      this.newAbsenceFormGroup.get('minutes').markAsTouched();
    }
    if (this.newAbsenceFormGroup.invalid) {
      this.invalidSubmit = true;
      return;
    } else {
      this.invalidSubmit = false;
    }

    const newAbsence = new AbsenceInfo();
    newAbsence.dateOfAbsence =
      this.newAbsenceFormGroup.get('dateOfAbsence').value;
    newAbsence.hours = this.newAbsenceFormGroup.get('hours').value;
    newAbsence.minutes = this.newAbsenceFormGroup.get('minutes').value;
    newAbsence.reason = this.newAbsenceFormGroup.get('reason').value;
    if (newAbsence.hours !== null) {
      this.absences.push(newAbsence);

      this.requestList.updateDataSource();
      this.newAbsenceFormGroup.reset();
      this.cdRef.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  async loadClaim() {
    await this.claimApi.getSummary(this.source, this.claimId).then((result) => {
      if (result != null && result != undefined) {
        this.summary = result;
      }
    });
  }

  async getIntermittentAbsenceSetupInfo() {
    // console.log('Config', this.config);
    const featureId = await this.config.getFeatureId(
      'SubmitIntermittentAbsences'
    );
    const setupInfo =
      await this.reportAbsenceService.getIntermittentAbsenceSetupAsync(
        this.session.user.contNum,
        this.summary.lob,
        'SubmitIntermittentAbsences'
      );

    if (setupInfo.message && setupInfo.message !== '') {
      this.clientMessage = setupInfo.message;
    }
    if (
      setupInfo.upperHours &&
      setupInfo.upperHours > 0 &&
      setupInfo.upperHours <= 24
    ) {
      this.clientDurationHours = setupInfo.upperHours;
    }
    if (
      setupInfo.upperMinutes &&
      setupInfo.upperMinutes > 0 &&
      setupInfo.upperMinutes <= 60
    ) {
      this.clientDurationMinutes = setupInfo.upperMinutes;
    }
    if (
      setupInfo.minuteInterval &&
      setupInfo.minuteInterval > 0 &&
      setupInfo.minuteInterval <= 60
    ) {
      this.clientMinutesIncrement = setupInfo.minuteInterval;
    }
  }

  async ngOnInit() {
    const params = getUrlParams();
    this.claimId = params.claimId;
    this.source = params.source;
    this.summary.claimId = this.claimId;
    this.summary.source = this.source;
    await this.loadClaim();
    await this.getIntermittentAbsenceSetupInfo();
    this.newAbsenceFormGroup = this.setValidators();
    this.newAbsenceFormGroup
      .get('dateOfAbsence')
      .addValidators([ValidatorsEx.existingDate()]);
    this.editAbsenceFormGroup = this.setValidators();
    this.editAbsenceFormGroup
      .get('dateOfAbsence')
      .addValidators([ValidatorsEx.existingDate()]);
    this.absenceReport.absences = [];
    this.session.loadUser();
    // console.log('report an absense summary', this.summary);
    this.cdRef.detectChanges();
  }

  setValidators() {
    //this.newAbsenceFormGroup =

    return this.formBuilder.group(
      {
        dateOfAbsence: [null as Date | null, [Validators.required]],
        hours: [
          null,
          [
            Validators.required,
            customvalidations.numberGreaterThan(0),
            customvalidations.numberLessThan(
              this.clientDurationHours,
              'Error : The duration entered exceeds the per day maximum.'
            ),
            customvalidations.IsNumeric(
              'Error : Please enter valid hours without decimals'
            ),
          ],
        ],
        minutes: [
          null,
          [
            Validators.required,
            customvalidations.numberGreaterThan(0),
            customvalidations.numberLessThan(
              this.clientDurationMinutes,
              'Error : The duration entered exceeds the per day maximum.'
            ),
            customvalidations.numberMustIncrementBy(
              this.clientMinutesIncrement,
              'Please report the duration in increments of ' +
                this.clientMinutesIncrement.toString() +
                ' minutes.'
            ),
            customvalidations.IsNumeric(
              'Error : Please enter valid minutes without decimals'
            ),
          ],
        ],
        reason: ['', [customvalidations.required]],
      },
      {
        validator: this.validateHoursAndMinutes,
      }
    );
  }

  getErrorMessage() {
    return 'Error : Reason for Absence is required.';
  }

  toggleMenu() {
    this.isActionsMenuExpanded = !this.isActionsMenuExpanded;
    if (this.isActionsMenuExpanded) {
      srSpeak('expanded', 'polite');
    }
  }
}
