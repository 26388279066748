import { Component, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Absence, ClaimantService } from '../../services/claimant.service';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import {
  DialogComponent,
  SelectOption,
  UserActionService,
} from 'src/app/shared';
import { IClaimant } from '../labels/claimant-label.component';
import { AbsenceService } from '../../services/absence.service';
import { customvalidations } from 'src/app/shared/models/customvalidations.validator';

@Component({
  selector: 'app-absence-add-dialog',
  template: `
    <app-dialog
      #submitAbsence
      title="Submit Absence"
      ariaLabel="Confirmation dialog"
      [width]="700"
      [showActions]="true"
      [showHeader]="true"
      (confirmEvent)="addAbsence()"
      confirmLabel="Submit"
      btnConfirmAltText="Submit"
      btnConfirmTitleText="Submit"
      cancelLabel="Cancel"
      btnCloseAltText="Cancel"
      btnCloseTitleText="Cancel"
    >
      <app-dialog-container
        id="outerDialog"
        class="container"
        [showHeader]="false"
      >
        <app-http-error
          class="tw-w-full"
          [httpErrorResponse]="null"
        ></app-http-error>
        <form
          [formGroup]="newAbsenceFormGroup"
          (ngSubmit)="(null)"
          class="tw-flex tw-flex-wrap tw-w-full"
        >
          <div class="tw-w-full app-pb1">
            <a (click)="openClient(employeeUid, employeeName, employeeId)">
              <span>{{ employeeName }} - {{ employeeId }}</span>
            </a>
          </div>
          @if(!responseSuccess){
          <div class="tw-w-full app-pb1">
            <label><strong>DATE OF ABSENCE</strong></label>
          </div>
          <div class="tw-w-full">
            <app-date-control
              formControlName="dateOfAbsence"
              [formGroup]="newAbsenceFormGroup"
              label="DATE OF ABSENCE"
              validationControl="Date of absence"
              ngDefaultControl
              class="tw-w-full"
            ></app-date-control>
          </div>
          <div class="tw-w-full app-pb1">
            <label><strong>RETURN TO WORK DATE</strong></label>
          </div>
          <div class="tw-w-full">
            <app-date-control
              formControlName="dateOfReturn"
              [formGroup]="newAbsenceFormGroup"
              label="RETURN TO WORK DATE"
              validationControl="Return to Work Date"
              ngDefaultControl
              class="tw-w-full"
            ></app-date-control>
          </div>
          <div class="tw-w-full app-pb1">
            <span><strong>ABSENCE HOURS AND MINUTES</strong></span>
          </div>
          <div>
            <div class="tw-w-[80%] md:tw-w-full app-mb4">
              <app-text-control
                formControlName="hours"
                [formGroup]="newAbsenceFormGroup"
                label="Hours"
                validationControl="Hours"
                type="number"
                [minTypeNumberValue]="0"
                ngDefaultControl
              ></app-text-control>
            </div>
          </div>

          <div>
            <div class="tw-w-[80%] md:tw-w-full app-mb4">
              <app-text-control
                formControlName="minutes"
                [formGroup]="newAbsenceFormGroup"
                label="Minutes"
                validationControl="Minutes"
                type="number"
                [minTypeNumberValue]="0"
                ngDefaultControl
              ></app-text-control>
            </div>
          </div>

          <div class="tw-w-full">
            <div class="tw-w-full">
              <app-select-control
                formControlName="category"
                [formGroup]="newAbsenceFormGroup"
                label="Reason"
                [options]="absenceCategorys"
                [multiple]="false"
                ngDefaultControl
                class="tw-w-full md:tw-w-[25%]"
              ></app-select-control>
            </div>
          </div>
          } @if(showError){
          <div class="app-pt2 tw-w-[65%]">
            <app-toast type="Error">
              Please review errors to add a new request.<br />
              {{ response.replace('Error: ', '') }}
            </app-toast>
          </div>
          } @if(responseSuccess) {
          <div class="app-pt2 tw-w-[65%]">
            <app-toast type="Info"> {{ response }} </app-toast>
          </div>
          }
        </form>
      </app-dialog-container>
    </app-dialog>
  `,
  styles: [``],
})
export class AbsenceAddDialogComponent implements AfterViewInit {
  @ViewChild('submitAbsence', { static: false }) submitAbsence: DialogComponent;
  employeeName: string = '';
  empUnum: string = '';
  employeeId: string = '';
  employeeUid: string = '';
  response: string = '';
  clientDurationHours: number = 24;
  clientDurationMinutes: number = 60;
  clientMinutesIncrement: number = 1;
  claimant: IClaimant;
  responseSuccess: boolean = false;
  validForm: boolean = false;
  showError: boolean = false;
  absenceCategorys: SelectOption[] = [];

  newAbsenceFormGroup = this.formBuilder.group(
    {
      dateOfAbsence: [null as Date | null, [Validators.required]],
      dateOfReturn: [null as Date | null, []],
      hours: [
        null,
        [
          Validators.required,
          Validators.min(0),
          Validators.max(21),
          customvalidations.IsNumeric(
            'Error : Please enter valid hours without decimals'
          ),
        ],
      ],
      minutes: [
        null,
        [
          Validators.required,
          Validators.min(0),
          Validators.max(59),
          customvalidations.IsNumeric(
            'Error : Please enter valid minutes without decimals'
          ),
        ],
      ],
      category: [null, [Validators.required]],
    },
    {
      validator: this.validateHoursAndMinutes,
    }
  );

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private claimantService: ClaimantService,
    private userAction: UserActionService,
    public absenceService: AbsenceService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.employeeName = data.employeeName;
    this.employeeId = data.employeeId;
    this.employeeUid = data.employeeUid;
    this.empUnum = data.empUnum;
    this.claimant = this.userAction.getClaimant();
  }

  async ngAfterViewInit(): Promise<void> {
    const categories = await this.claimantService.GetAbsenceCategorys();
    for (const key in categories) {
      this.absenceCategorys.push({
        value: key,
        label: categories[key],
      });
    }

    this.newAbsenceFormGroup.valueChanges.subscribe(() => {
      this.validForm = this.validateForm();
      this.showError = false;
    });

    const max30Day = new Date();
    max30Day.setDate(new Date().getDate() + 30);
    const max2Year = new Date(
      new Date().getFullYear() + 2,
      new Date().getMonth(),
      new Date().getDate()
    );
    const max2YearAgo = new Date(
      new Date().getFullYear() - 2,
      new Date().getMonth(),
      new Date().getDate()
    );
    this.newAbsenceFormGroup
      .get('dateOfAbsence')
      .addValidators([
        customvalidations.MinDate(max2YearAgo),
        customvalidations.MaxDate(max2Year),
        customvalidations.dateLessThan(
          this.newAbsenceFormGroup.get('dateOfReturn'),
          false,
          'Return to work date cannot be before date of absence.'
        ),
      ]);

    this.newAbsenceFormGroup
      .get('dateOfReturn')
      .addValidators([
        customvalidations.MaxDate(max30Day),
        customvalidations.dateGreaterThan(
          this.newAbsenceFormGroup.get('dateOfAbsence'),
          false,
          'Date of absence cannot be after return to work date.'
        ),
      ]);
  }

  validateHoursAndMinutes(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const hours = control.get('hours').value;
    const minutes = control.get('minutes').value;
    if (hours === null && minutes === null) {
      return null;
    }
    if ((hours === 0 || hours === '0') && (minutes === 0 || minutes === '0')) {
      control.get('minutes').setValue('');
      control.updateValueAndValidity();
      return { Invalid: true };
    }

    return null;
  }

  validateForm(touch: boolean = false) {
    let valid = true;
    Object.keys(this.newAbsenceFormGroup.controls).forEach((key) => {
      if (!this.newAbsenceFormGroup.controls[key].valid) {
        valid = false;
      }
      if (touch) {
        this.newAbsenceFormGroup.controls[key].markAsTouched();
      }
    });
    return valid;
  }

  async addAbsence() {
    this.validForm = this.validateForm(true);
    if (this.newAbsenceFormGroup.invalid) {
      return;
    }

    const newAbsence = new Absence();
    newAbsence.dateOfAbsence = new Date(
      this.newAbsenceFormGroup.get('dateOfAbsence').value
    );
    // this is to handle a string delete of the dateOfReturn
    if (this.newAbsenceFormGroup.get('dateOfReturn').value === '') {
      this.newAbsenceFormGroup.get('dateOfReturn').setValue(null);
    }
    newAbsence.dateOfReturn = new Date(
      this.newAbsenceFormGroup.get('dateOfReturn').value
    );
    newAbsence.hours = Math.floor(
      parseInt(this.newAbsenceFormGroup.get('hours').value)
    ).toString();
    newAbsence.minutes = Math.floor(
      parseInt(this.newAbsenceFormGroup.get('minutes').value)
    ).toString();
    newAbsence.absenceCategory = this.newAbsenceFormGroup.get('category').value;
    newAbsence.reason = this.newAbsenceFormGroup.get('category').value;
    newAbsence.employeeId = this.employeeId;
    newAbsence.employeeName = this.employeeName;
    newAbsence.empUnum = this.empUnum;
    this.response = await this.claimantService.submitClaimantAbsence(
      newAbsence
    );
    this.responseSuccess = !this.response.includes('Error:');
    if (this.responseSuccess) {
      this.newAbsenceFormGroup.reset();
      this.submitAbsence.showConfirm = false;
      this.submitAbsence.cancelLabel = 'Close';
    } else {
      this.showError = true;
      setTimeout(() => {
        const dialog = document.getElementById('outerDialog');
        const parent = dialog.parentElement;
        parent.scrollTop = parent.scrollHeight;
      }, 100);
    }
  }

  openClient(empUnum: string, name: string, empId: string) {
    this.absenceService.navigateToAbsences(empUnum, name, empId);
    this.dialog.closeAll();
  }
}
