import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { reportNewClaim } from 'src/app/my-sedgwick/functions/report-newclaim';
import { scrollToElement, getUrlParams } from 'src/app/shared';
import { ExternalLinkDialogComponent } from 'src/app/shared/components/controls/dialog/external-link-dialog.component';
import { SubSink } from 'subsink';
import { DisplayService, DynamicEventBindService } from '../..';
import { ConfigurationService } from '../../services/configuration.service';
import { LearningCenterService } from '../../services/learning-center.service';
import { OpenFileService } from '../../services/openfile.service';
import { Session } from '../../services/session.service';

@Component({
  selector: 'app-learning-center-lv',
  template: `
    <div #dynamicContentContainer class="tw-w-full tw-flex">
      <!-- CONTENT -->
      <!-- Introduction starts -->
      <div *ngIf="!loading" class="tw-w-full">
        <h2
          class="app-lc-header-2"
          id="lc-intro"
          *ngIf="isIntroduction"
          role="heading"
        >
          Introduction
        </h2>
        <div class="text">
          <p [innerHTML]="learningCenter.introductionResults[0].value"></p>
        </div>
        <h2
          *ngIf="isWhatisLV"
          [innerHTML]="learningCenter.introductionResults[1].value"
          class="app-lc-header-2"
          id="lc-what"
          role="heading"
        ></h2>

        <div class="app-lc-text" *ngIf="isWhatisLV">
          <div class="right-wrap tw-flex-none app-pl2 tw-hidden md:tw-block">
            <img
              src="./assets/images/img_LC_introphone_desktop.webp"
              width="69"
              height="100"
              alt=""
              role="img"
            />
          </div>
          <p [innerHTML]="learningCenter.introductionResults[2]?.value"></p>
          <p [innerHTML]="learningCenter.introductionResults[3]?.value"></p>
          <p [innerHTML]="learningCenter.introductionResults[4]?.value"></p>
          <p [innerHTML]="learningCenter.introductionResults[5]?.value"></p>
        </div>
        <!-- Introduction ends -->
        <!-- FMLA Details starts -->

        <h2
          class="app-lc-header-2"
          id="lc-Details"
          *ngIf="isFMLADetails"
          role="heading"
        >
          FMLA Details
        </h2>
        <div *ngIf="isMgr; then mgrFMLADetails; else nonmgrFMLADetails"></div>
        <ng-template #mgrFMLADetails>
          <div class="app-lc-text">
            <p>
              <b
                *ngIf="
                  learningCenter.fmlaDetails[1].value ||
                  learningCenter.fmlaDetails[2].value
                "
                >{{ learningCenter.fmlaDetails[0].value }}</b
              >
            </p>
            <p [innerHTML]="learningCenter.fmlaDetails[1].value"></p>
            <p [innerHTML]="learningCenter.fmlaDetails[2].value"></p>
          </div>
          <br />
          <div class="app-lc-text">
            <p>
              <b
                *ngIf="
                  learningCenter.fmlaDetails[4].value ||
                  learningCenter.fmlaDetails[5].value
                "
                >{{ learningCenter.fmlaDetails[3].value }}</b
              >
            </p>
            <p [innerHTML]="learningCenter.fmlaDetails[4].value"></p>
            <p [innerHTML]="learningCenter.fmlaDetails[5].value"></p>
          </div>
          <br />
          <div class="app-lc-text">
            <p>
              <b
                *ngIf="
                  learningCenter.fmlaDetails[7].value ||
                  learningCenter.fmlaDetails[8].value
                "
                >{{ learningCenter.fmlaDetails[6].value }}</b
              >
            </p>
            <p [innerHTML]="learningCenter.fmlaDetails[7].value"></p>
            <p [innerHTML]="learningCenter.fmlaDetails[8].value"></p>
          </div>
          <br />
          <div class="app-lc-text">
            <p>
              <b
                *ngIf="
                  learningCenter.fmlaDetails[10].value ||
                  learningCenter.fmlaDetails[11].value
                "
                >{{ learningCenter.fmlaDetails[9].value }}</b
              >
            </p>
            <p [innerHTML]="learningCenter.fmlaDetails[10].value"></p>
            <p [innerHTML]="learningCenter.fmlaDetails[11].value"></p>
          </div>
          <br />
          <div class="app-lc-text">
            <p>
              <b
                *ngIf="
                  learningCenter.fmlaDetails[13].value ||
                  learningCenter.fmlaDetails[14].value ||
                  learningCenter.fmlaDetails[15].value ||
                  learningCenter.fmlaDetails[16].value
                "
                >{{ learningCenter.fmlaDetails[12].value }}</b
              >
            </p>
            <p [innerHTML]="learningCenter.fmlaDetails[13].value"></p>
            <p [innerHTML]="learningCenter.fmlaDetails[14].value"></p>
            <p [innerHTML]="learningCenter.fmlaDetails[15].value"></p>
            <p [innerHTML]="learningCenter.fmlaDetails[16].value"></p>
          </div>
        </ng-template>
        <ng-template #nonmgrFMLADetails>
          <div class="app-lc-text">
            <p>
              <b
                *ngIf="
                  learningCenter.fmlaDetails[1].value ||
                  learningCenter.fmlaDetails[2].value
                "
                >{{ learningCenter.fmlaDetails[0].value }}</b
              >
            </p>
            <p [innerHTML]="learningCenter.fmlaDetails[1].value"></p>
            <p [innerHTML]="learningCenter.fmlaDetails[2].value"></p>
          </div>
          <br />
          <div class="app-lc-text">
            <p>
              <b
                *ngIf="
                  learningCenter.fmlaDetails[4].value ||
                  learningCenter.fmlaDetails[5].value
                "
                >{{ learningCenter.fmlaDetails[3].value }}</b
              >
            </p>
            <p [innerHTML]="learningCenter.fmlaDetails[4].value"></p>
            <p [innerHTML]="learningCenter.fmlaDetails[5].value"></p>
          </div>
          <br />
          <div class="app-lc-text">
            <p>
              <b
                *ngIf="
                  learningCenter.fmlaDetails[7].value ||
                  learningCenter.fmlaDetails[8].value
                "
                >{{ learningCenter.fmlaDetails[6].value }}</b
              >
            </p>
            <p [innerHTML]="learningCenter.fmlaDetails[7].value"></p>
            <p [innerHTML]="learningCenter.fmlaDetails[8].value"></p>
          </div>
          <br />
          <div class="app-lc-text">
            <p>
              <b
                *ngIf="
                  learningCenter.fmlaDetails[10].value ||
                  learningCenter.fmlaDetails[11].value
                "
                >{{ learningCenter.fmlaDetails[9].value }}</b
              >
            </p>
            <p [innerHTML]="learningCenter.fmlaDetails[10].value"></p>
            <p [innerHTML]="learningCenter.fmlaDetails[11].value"></p>
          </div>
          <br />
          <div class="app-lc-text">
            <p>
              <b
                *ngIf="
                  learningCenter.fmlaDetails[13].value ||
                  learningCenter.fmlaDetails[14].value ||
                  learningCenter.fmlaDetails[15].value ||
                  learningCenter.fmlaDetails[16].value
                "
                >{{ learningCenter.fmlaDetails[12].value }}</b
              >
            </p>
            <p [innerHTML]="learningCenter.fmlaDetails[13].value"></p>
            <p [innerHTML]="learningCenter.fmlaDetails[14].value"></p>
            <p [innerHTML]="learningCenter.fmlaDetails[15].value"></p>
            <p [innerHTML]="learningCenter.fmlaDetails[16].value"></p>
          </div>
        </ng-template>

        <!-- FMLA Details ends -->

        <!-- Submitting Claim Information -->

        <h2 class="app-lc-header-2" id="lc-sub" role="heading">
          FMLA Submitting Claim Information
        </h2>
        <div *ngIf="isMgr; then mgrSubmitInfo; else nonMgrSubmitInfo"></div>
        <ng-template #mgrSubmitInfo>
          <div>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[0].value }}"
              icon="1"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[1].value
                "
              ></p>
              <p>
                <b>{{ learningCenter.submittingclaiminformations[2].value }}</b>
              </p>
              <div class="app-p">
                <ul class="tw-list-disc tw-list-inside">
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[3]?.value"
                    [innerHtml]="
                      learningCenter.submittingclaiminformations[3].value
                    "
                  >
                    {{ learningCenter.submittingclaiminformations[3].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[4]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[4].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[5]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[5].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[6]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[6].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[7]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[7].value }}
                  </li>
                </ul>
              </div>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[8].value
                "
              ></p>
            </app-learning-center-section>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[9].value }}"
              icon="2"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[10].value
                "
              ></p>
              <p
                *ngIf="isShown"
                [innerHTML]="
                  learningCenter.submittingclaiminformations[11].value
                "
              ></p>
            </app-learning-center-section>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[12].value }}"
              icon="3"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[13].value
                "
              ></p>
              <p
                *ngIf="isShown"
                [innerHTML]="
                  learningCenter.submittingclaiminformations[14].value
                "
              ></p>
              <p>
                <b>
                  {{ learningCenter.submittingclaiminformations[15].value }}</b
                >
              </p>
              <p>
                <b>
                  {{ learningCenter.submittingclaiminformations[16].value }}</b
                >
              </p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[17].value
                "
              ></p>
              <p>
                <b>
                  {{ learningCenter.submittingclaiminformations[18].value }}</b
                >
              </p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[19].value
                "
              ></p>
            </app-learning-center-section>
            <app-learning-center-section
              *ngIf="
                this.learningCenter.submittingclaiminformations[20].value ||
                this.learningCenter.submittingclaiminformations[21].value ||
                this.learningCenter.submittingclaiminformations[22].value ||
                this.learningCenter.submittingclaiminformations[23].value ||
                this.learningCenter.submittingclaiminformations[24].value
              "
              label="{{ learningCenter.submittingclaiminformations[20].value }}"
              icon="4"
              layout="bullet"
            >
              <div
                class="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-items-stretch"
              >
                <app-learning-center-section
                  *ngIf="
                    this.learningCenter.submittingclaiminformations[21].value &&
                    this.claimConfigService.getFeatureAccess(
                      'UploadDocuments'
                    ) &&
                    isMgr &&
                    isShown
                  "
                  class="tw-basis-1/4 app-color-teal"
                  layout="icon-small"
                  icon="upload_file"
                >
                  <a
                    tabindex="0"
                    label="Documents"
                    class="go-to-documents-upload"
                    >Upload Documents</a
                  >
                </app-learning-center-section>
                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[22]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="mail"
                >
                  Mail:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[22]?.value
                    "
                  ></span>
                </app-learning-center-section>

                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[23]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="fax"
                >
                  Fax:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[23]?.value
                    "
                  ></span>
                </app-learning-center-section>
                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[24]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="laptop_mac"
                >
                  E-Mail:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[24]?.value
                    "
                  ></span>
                </app-learning-center-section>
              </div>
            </app-learning-center-section>
          </div>
        </ng-template>
        <ng-template #nonMgrSubmitInfo>
          <div>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[0].value }}"
              icon="1"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[1].value
                "
              ></p>
              <p>
                <b>{{ learningCenter.submittingclaiminformations[2].value }}</b>
              </p>
              <div class="app-p">
                <ul class="tw-list-disc tw-list-inside">
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[3]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[3].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[4]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[4].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[5]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[5].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[6]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[6].value }}
                  </li>
                  <li
                    *ngIf="learningCenter.submittingclaiminformations[7]?.value"
                  >
                    {{ learningCenter.submittingclaiminformations[7].value }}
                  </li>
                </ul>
              </div>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[8].value
                "
              ></p>
            </app-learning-center-section>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[9].value }}"
              icon="2"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[10].value
                "
              ></p>
              <p
                *ngIf="isShown"
                [innerHTML]="
                  learningCenter.submittingclaiminformations[11]?.value
                "
              ></p>
            </app-learning-center-section>
            <app-learning-center-section
              label="{{ learningCenter.submittingclaiminformations[12].value }}"
              icon="3"
              layout="bullet"
            >
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[13].value
                "
              ></p>
              <p
                *ngIf="isShown"
                [innerHTML]="
                  learningCenter.submittingclaiminformations[14].value
                "
              ></p>
              <p>
                <b>
                  {{ learningCenter.submittingclaiminformations[15].value }}</b
                >
              </p>
              <p>
                <b>
                  {{ learningCenter.submittingclaiminformations[16].value }}</b
                >
              </p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[17].value
                "
              ></p>
              <p>
                <b>
                  {{ learningCenter.submittingclaiminformations[18].value }}</b
                >
              </p>
              <p
                [innerHTML]="
                  learningCenter.submittingclaiminformations[19].value
                "
              ></p>
            </app-learning-center-section>
            <app-learning-center-section
              *ngIf="
                this.learningCenter.submittingclaiminformations[20].value ||
                this.learningCenter.submittingclaiminformations[21].value ||
                this.learningCenter.submittingclaiminformations[22].value ||
                this.learningCenter.submittingclaiminformations[23].value ||
                this.learningCenter.submittingclaiminformations[24].value
              "
              label="{{ learningCenter.submittingclaiminformations[20].value }}"
              icon="4"
              layout="bullet"
            >
              <div
                class="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-items-stretch"
              >
                <app-learning-center-section
                  *ngIf="
                    learningCenter.submittingclaiminformations[21]?.value &&
                    this.claimConfigService.getFeatureAccess(
                      'UploadDocuments'
                    ) &&
                    !isMgr &&
                    isShown
                  "
                  class="tw-basis-1/4 app-color-teal"
                  layout="icon-small"
                  icon="upload_file"
                >
                  <a
                    tabindex="0"
                    label="Documents"
                    class="go-to-documents-upload"
                    >Upload Documents</a
                  >
                </app-learning-center-section>
                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[22]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="mail"
                >
                  Mail:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[22]?.value
                    "
                  ></span>
                </app-learning-center-section>

                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[23]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="fax"
                >
                  Fax:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[23]?.value
                    "
                  ></span>
                </app-learning-center-section>
                <app-learning-center-section
                  *ngIf="learningCenter.submittingclaiminformations[24]?.value"
                  class="tw-basis-1/4"
                  layout="icon-small"
                  icon="laptop_mac"
                >
                  E-Mail:<br />
                  <span
                    [innerHTML]="
                      learningCenter.submittingclaiminformations[24]?.value
                    "
                  ></span>
                </app-learning-center-section>
              </div>
            </app-learning-center-section>
          </div>
        </ng-template>
        <!-- Submitting Claim Information ends -->

        <!--FMLA what next -->

        <h2
          class="app-lc-header-2"
          id="lc-next"
          *ngIf="isWhatsnext"
          role="heading"
        >
          What's Next
        </h2>
        <div *ngIf="isMgr; then mgrNext; else nonMgrNext"></div>
        <ng-template #mgrNext>
          <div class="app-lc-text">
            <div
              class="right-wrap app-pl2 tw-hidden md:tw-block"
              *ngIf="isWhatsnext"
            >
              <img
                src="./assets/images/img_LC_WhatsNext_computer.webp"
                width="141"
                height="100"
                alt=""
                role="img"
              />
            </div>
            <p [innerHTML]="learningCenter.whatsnexts[0].value"></p>
            <div
              *ngIf="
                lcType === 'CONTROLLER' || lcType === 'MANAGER';
                then mgrNextLcType;
                else nonMgrNextLcType
              "
            ></div>
            <ng-template #mgrNextLcType>
              <p
                *ngIf="
                  (isShown &&
                    learningCenter.whatsnexts[2]?.value &&
                    learningCenter.whatsnexts[3]?.value) ||
                  (this.claimConfigService.getConfigFeatureAccess(
                    'notificationpreferences'
                  ) &&
                    learningCenter.whatsnexts[5]?.value &&
                    learningCenter.whatsnexts[6]?.value) ||
                  (learningCenter.whatsnexts[7]?.value &&
                    learningCenter.whatsnexts[8]?.value &&
                    learningCenter.whatsnexts[9]?.value)
                "
                [innerHTML]="learningCenter.whatsnexts[1]?.value"
              ></p>
              <div>
                <app-learning-center-section
                  *ngIf="isShown && learningCenter.whatsnexts[3]?.value"
                  label="{{ learningCenter.whatsnexts[2]?.value }}"
                  icon="laptop_mac"
                  layout="icon"
                >
                  <p [innerHTML]="learningCenter.whatsnexts[3]?.value"></p>
                </app-learning-center-section>
              </div>
              <app-learning-center-section
                *ngIf="learningCenter.whatsnexts[5]?.value"
                layout="icon"
                icon="chat"
                label="{{ learningCenter.whatsnexts[4].value }}"
              >
                <p [innerHTML]="learningCenter.whatsnexts[5].value"></p>
              </app-learning-center-section>
            </ng-template>
            <ng-template #nonMgrNextLcType>
              <p
                *ngIf="
                  (isShown && learningCenter.whatsnexts[3]?.value) ||
                  (learningCenter.whatsnexts[4]?.value &&
                    learningCenter.whatsnexts[5]?.value) ||
                  (learningCenter.whatsnexts[7]?.value &&
                    learningCenter.whatsnexts[8]?.value &&
                    learningCenter.whatsnexts[9]?.value &&
                    learningCenter.whatsnexts[9]?.value) ||
                  (this.claimConfigService.getConfigFeatureAccess(
                    'notificationpreferences'
                  ) &&
                    learningCenter.whatsnexts[5]?.value &&
                    learningCenter.whatsnexts[6]?.value)
                "
                [innerHTML]="learningCenter.whatsnexts[1]?.value"
              ></p>

              <div>
                <app-learning-center-section
                  *ngIf="isShown && learningCenter.whatsnexts[3]?.value"
                  label="{{ learningCenter.whatsnexts[2]?.value }}"
                  icon="laptop_mac"
                  layout="icon"
                >
                  <p [innerHTML]="learningCenter.whatsnexts[3]?.value"></p>
                </app-learning-center-section>
              </div>
              <app-learning-center-section
                *ngIf="
                  learningCenter.whatsnexts[5]?.value &&
                  this.claimConfigService.getConfigFeatureAccess(
                    'notificationpreferences'
                  )
                "
                layout="icon"
                icon="chat"
                label="{{ learningCenter.whatsnexts[4].value }}"
              >
                <p [innerHTML]="learningCenter.whatsnexts[5].value"></p>
              </app-learning-center-section>
            </ng-template>

            <div>
              <app-learning-center-section
                *ngIf="learningCenter.whatsnexts[8]?.value"
                label="{{ learningCenter.whatsnexts[6]?.value }}"
                icon="contact_phone"
                layout="icon"
              >
                <p
                  [innerHTML]="
                    learningCenter.whatsnexts[7]?.value +
                    ' ' +
                    learningCenter.whatsnexts[8]?.value +
                    ' ' +
                    learningCenter.whatsnexts[9]?.value
                  "
                ></p>
              </app-learning-center-section>
            </div>
          </div>
        </ng-template>
        <ng-template #nonMgrNext>
          <div class="app-lc-text">
            <div
              class="right-wrap app-pl2 tw-hidden md:tw-block"
              *ngIf="isWhatsnext"
            >
              <img
                src="./assets/images/img_LC_WhatsNext_computer.webp"
                width="141"
                height="100"
                alt=""
                role="img"
              />
            </div>
            <p [innerHTML]="learningCenter.whatsnexts[0].value"></p>
            <p
              *ngIf="
                (isShown && learningCenter.whatsnexts[3]?.value) ||
                (learningCenter.whatsnexts[4]?.value &&
                  learningCenter.whatsnexts[5]?.value) ||
                (learningCenter.whatsnexts[7]?.value &&
                  learningCenter.whatsnexts[8]?.value &&
                  learningCenter.whatsnexts[9]?.value &&
                  learningCenter.whatsnexts[9]?.value) ||
                (this.claimConfigService.getConfigFeatureAccess(
                  'notificationpreferences'
                ) &&
                  learningCenter.whatsnexts[5]?.value &&
                  learningCenter.whatsnexts[6]?.value)
              "
              [innerHTML]="learningCenter.whatsnexts[1]?.value"
            ></p>
            <div>
              <app-learning-center-section
                *ngIf="isShown && learningCenter.whatsnexts[3]?.value"
                label="{{ learningCenter.whatsnexts[2]?.value }}"
                icon="laptop_mac"
                layout="icon"
              >
                <p [innerHTML]="learningCenter.whatsnexts[3]?.value"></p>
              </app-learning-center-section>
            </div>
            <app-learning-center-section
              *ngIf="
                this.claimConfigService.getConfigFeatureAccess(
                  'notificationpreferences'
                ) && learningCenter.whatsnexts[5]?.value
              "
              layout="icon"
              icon="chat"
              label="{{ learningCenter.whatsnexts[4].value }}"
            >
              <p [innerHTML]="learningCenter.whatsnexts[5]?.value"></p>
            </app-learning-center-section>
            <div>
              <app-learning-center-section
                *ngIf="learningCenter.whatsnexts[8]?.value"
                label="{{ learningCenter.whatsnexts[6]?.value }}"
                icon="contact_phone"
                layout="icon"
              >
                <p
                  [innerHTML]="
                    learningCenter.whatsnexts[7]?.value +
                    ' ' +
                    learningCenter.whatsnexts[8]?.value +
                    ' ' +
                    learningCenter.whatsnexts[9]?.value
                  "
                ></p>
              </app-learning-center-section>
            </div>
          </div>
        </ng-template>
        <!-- FMLA what next ends -->
        <!-- FMLA After Decision starts-->

        <h2
          class="app-lc-header-2"
          id="lc-after"
          *ngIf="isAfterdecision"
          role="heading"
        >
          FMLA After Your Decision is Made
        </h2>
        <div *ngIf="isMgr; then mgrDecision; else nonMgrDecision"></div>
        <ng-template #mgrDecision>
          <div class="app-lc-text">
            <p>
              <b
                *ngIf="learningCenter.afterDecide[1].value"
                [innerHTML]="learningCenter.afterDecide[0].value"
              ></b
              ><br />
              <span [innerHTML]="learningCenter.afterDecide[1].value"></span>
            </p>
            <p>
              <b
                *ngIf="learningCenter.afterDecide[3].value"
                [innerHTML]="learningCenter.afterDecide[2].value"
              ></b
              ><br />
              <span [innerHTML]="learningCenter.afterDecide[3].value"></span>
            </p>
            <p>
              <b
                *ngIf="learningCenter.afterDecide[5].value"
                [innerHTML]="learningCenter.afterDecide[4].value"
              ></b
              ><br />
              <span [innerHTML]="learningCenter.afterDecide[5].value"></span>
            </p>
            <p>
              <b
                *ngIf="learningCenter.afterDecide[7].value"
                [innerHTML]="learningCenter.afterDecide[6].value"
              ></b
              ><br />
              <span [innerHTML]="learningCenter.afterDecide[7].value"></span>
            </p>
            <p>
              <b
                *ngIf="learningCenter.afterDecide[9].value"
                [innerHTML]="learningCenter.afterDecide[8].value"
              ></b
              ><br />
              <span [innerHTML]="learningCenter.afterDecide[9].value"></span>
            </p>
            <p>
              <b
                *ngIf="learningCenter.afterDecide[11]?.value"
                [innerHTML]="learningCenter.afterDecide[10]?.value"
              ></b
              ><br />
              <span [innerHTML]="learningCenter.afterDecide[11]?.value"></span>
            </p>
          </div>
        </ng-template>
        <ng-template #nonMgrDecision>
          <div class="app-lc-text">
            <p>
              <b
                *ngIf="learningCenter.afterDecide[1].value"
                [innerHTML]="learningCenter.afterDecide[0].value"
              ></b
              ><br />
              <span [innerHTML]="learningCenter.afterDecide[1].value"></span>
            </p>
            <p>
              <b
                *ngIf="learningCenter.afterDecide[3].value"
                [innerHTML]="learningCenter.afterDecide[2].value"
              ></b
              ><br />
              <span [innerHTML]="learningCenter.afterDecide[3].value"></span>
            </p>
            <p>
              <b
                *ngIf="learningCenter.afterDecide[5].value"
                [innerHTML]="learningCenter.afterDecide[4].value"
              ></b
              ><br />
              <span [innerHTML]="learningCenter.afterDecide[5].value"></span>
            </p>
            <p>
              <b
                *ngIf="learningCenter.afterDecide[7].value"
                [innerHTML]="learningCenter.afterDecide[6].value"
              ></b
              ><br />
              <span [innerHTML]="learningCenter.afterDecide[7].value"></span>
            </p>
            <p>
              <b
                *ngIf="learningCenter.afterDecide[9].value"
                [innerHTML]="learningCenter.afterDecide[8].value"
              ></b
              ><br />
              <span [innerHTML]="learningCenter.afterDecide[9].value"></span>
            </p>
            <p>
              <b
                *ngIf="learningCenter.afterDecide[11]?.value"
                [innerHTML]="learningCenter.afterDecide[10]?.value"
              ></b
              ><br />
              <span [innerHTML]="learningCenter.afterDecide[11]?.value"></span>
            </p>
          </div>
        </ng-template>
        <!-- FMLA After Decision ends -->
        <!-- TODO -->

        <h2 class="app-lc-header-2" id="lc-how" *ngIf="!isShown" role="heading">
          How Do All of These Claims Work?
        </h2>
        <div *ngIf="!isShown" class="text">
          Wondering what types of claims apply to what situations? Take a look
          at the table below for a quick overview.
        </div>
        <ng-container *ngIf="!isShown">
          <table class="tw-w-full" aria-label="Claims Information">
            <thead>
              <tr>
                <th style="width:20%" colspan="1" scope="col"></th>
                <th style="width:20%" class="table-header" scope="col">
                  Short Term Disability
                </th>
                <th style="width:20%" class="table-header" scope="col">
                  FMLA/State Leave
                </th>
                <th style="width:20%" class="table-header" scope="col">
                  Workers' Compensation
                </th>
                <th style="width:20%" class="table-header" scope="col">ADA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="table-header-left" scope="row">
                  Unable to work due to an injury that occured outside of work
                </th>
                <td class="check-container">
                  <mat-icon
                    class="check"
                    aria-label="Unable to work due to an injury that occured outside of work checked"
                    tabindex="0"
                    aria-hidden="false"
                    >check</mat-icon
                  >
                </td>
                <td class="check-container">
                  <mat-icon
                    class="check"
                    aria-label="Unable to work due to an injury that occured outside of work checked"
                    tabindex="0"
                    scope="row"
                    aria-hidden="false"
                    >check</mat-icon
                  >
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <th class="table-header-left" scope="row">
                  Unable to work due to an injury that occured at work
                </th>
                <td></td>
                <td class="check-container">
                  <mat-icon
                    class="check"
                    aria-label="Unable to work due to an injury that occured at work checked"
                    tabindex="0"
                    aria-hidden="false"
                    >check</mat-icon
                  >
                </td>
                <td class="check-container">
                  <mat-icon
                    class="check"
                    aria-label="Unable to work due to an injury that occured at work checked"
                    tabindex="0"
                    aria-hidden="false"
                    >check</mat-icon
                  >
                </td>
                <td></td>
              </tr>
              <tr>
                <th class="table-header-left" scope="row">
                  Unable to work 2 days every month due to my own chronic health
                  condition
                </th>
                <td></td>
                <td class="check-container">
                  <mat-icon
                    class="check"
                    aria-label="Unable to work 2 days every month due to my own chronic health
            condition checked"
                    tabindex="0"
                    aria-hidden="false"
                    >check</mat-icon
                  >
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <th scope="row" class="table-header-left">
                  Unable to work for 3 weeks to care for an ill family member
                </th>
                <td></td>
                <td class="check-container">
                  <mat-icon
                    class="check"
                    aria-label="Unable to work for 3 weeks to care for an ill family member checked"
                    tabindex="0"
                    aria-hidden="false"
                    >check</mat-icon
                  >
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th scope="row" class="table-header-left">
                  Unable to be totally successful at work due to limited
                  functionality
                </th>
                <td></td>
                <td></td>
                <td></td>
                <td class="check-container">
                  <mat-icon
                    class="check"
                    aria-label="Unable to be totally successful at work due to limited functionality checked"
                    tabindex="0"
                    aria-hidden="false"
                    >check</mat-icon
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>

      <!-- RHS MENU -->
      <div
        class="tw-hidden lg:tw-block tw-flex-none tw-max-w-[220px] app-lc-menu"
      >
        <app-sticky-menu>
          <div class="app-pt2">
            <div class="app-lc-sticky-link"><b>FMLA</b></div>
            <div class="app-lc-sticky-link" *ngIf="isIntroduction">
              <a (click)="scrollToElement('lc-intro', false, 64)"
                >Introduction</a
              >
            </div>
            <div class="app-lc-sticky-link" *ngIf="isFMLADetails">
              <a (click)="scrollToElement('lc-Details', false, 64)"
                >FMLA Details</a
              >
            </div>

            <div class="app-lc-sticky-link">
              <a (click)="scrollToElement('lc-sub', false, 64)"
                >FMLA Submitting Claim Information</a
              >
            </div>
            <div class="app-lc-sticky-link" *ngIf="isWhatsnext">
              <a (click)="scrollToElement('lc-next', false, 64)"
                >FMLA What's Next</a
              >
            </div>
            <div class="app-lc-sticky-link" *ngIf="isAfterdecision">
              <a (click)="scrollToElement('lc-after', false, 64)"
                >FMLA After your Decision is made</a
              >
            </div>
            <div *ngIf="!isShown" class="app-lc-sticky-link">
              <a (click)="scrollToElement('lc-how', false, 64)"
                >How Do All of These Claims Work</a
              >
            </div>
          </div>
        </app-sticky-menu>
      </div>
    </div>
  `,
  styles: [
    `
      table td {
        border-color: silver;
        border-style: solid;
        border-width: 0 1px 1px 0;
      }

      .tblcolumn {
        width: 20%;
      }

      .app-lc-header-2 {
        color: var(--color-teal);
        font-size: 28px;
        padding: 32px 0 16px 0;
        font-weight: bold;
        line-height: 34px;
      }
      .app-lc-header {
        color: var(--color-teal);
        font-size: 32px;
        padding: 32px 0 16px 0;
        font-weight: bold;
        line-height: 34px;
      }
      .app-lc-text {
      }
      .app-lc-sticky-link {
        padding: 4px 0 4px 0;
      }
      .app-lc-menu {
        border-left: 1px solid var(--color-light-grey);
        padding-left: 16px;
        margin-left: 16px;
      }
      .table-container {
      }
      .table-container > div {
        border-color: silver;
        border-style: solid;
        border-width: 0 1px 1px 0;
      }
      .check-container {
        text-align: center;
        vertical-align: middle;
        line-height: 48px;
      }
      .check {
        font-size: 48px;
        width: 48px;
        height: 48px;
        color: var(--color-teal);
      }
      .app-lc-header {
        color: var(--color-teal);
        font-size: 32px;
        padding: 32px 0 16px 0;
        font-weight: bold;
        line-height: 34px;
      }
      .app-lc-text {
      }
      .app-lc-sticky-link {
        padding: 4px 0 4px 0;
      }
      .app-lc-menu {
        border-left: 1px solid var(--color-light-grey);
        padding-left: 16px;
        margin-left: 16px;
      }
      .left-wrap {
        float: left;
      }
      .right-wrap {
        float: right;
      }
      .center {
        text-align: center;
      }
      .mat-icon {
        margin: auto;
      }
      p + p {
        margin-top: 10px;
      }
      .app-p {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    `,
  ],
})
export class LearningCenterLvComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input()
  isMgr: boolean;

  @Input()
  learningCenter: any;

  @Input()
  loading: boolean;
  isShown: boolean = false;

  scrollToElement = scrollToElement;
  @Input()
  lcType: string;
  isReportClaimLinkBound: boolean = false;
  isOpenfile: boolean = false;
  documentUrl: string;
  isLearnMoreLinkBound: boolean = false;
  isLearnMore: boolean = false;
  mappings: any;

  isIntroduction: boolean = false;
  isWhatisLV: boolean = false;
  isFMLADetails: boolean = false;
  isSubmittingClaim: boolean = false;
  isWhatsnext: boolean = false;
  isAfterdecision: boolean = false;

  @ViewChild('dynamicContentContainer') container: ElementRef;
  router: any;
  private unlistens: (() => void)[] = [];
  constructor(
    private session: Session,
    public learningCenterService: LearningCenterService,
    private dialog: MatDialog,
    public openFileService: OpenFileService,
    private bindEvents: DynamicEventBindService,
    private renderer: Renderer2,
    public claimConfigService: ConfigurationService,
    private display: DisplayService
  ) {}

  subs = new SubSink();

  ngAfterViewInit(): void {
    this.unlistens = this.bindEvents.bindDynamicEvents(
      this.container,
      this.mappings
    );
  }

  async ngOnInit() {
    const params = getUrlParams();
    //console.log(this.learningCenter);

    this.buildDocumentLink();
    this.checkHeaderValues();
    this.mappings = [
      {
        selector: '.report-new-claim',
        action: this.reportClaim.bind(this),
      },
      {
        selector: '.medical-provider',
        action: this.openFileLink.bind(this),
      },
      {
        selector: '.learn-more',
        action: this.openLearnMore.bind(this),
      },
      {
        selector: '.go-to-personal-notif',
        route: '/PreferencesPage',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
        },
      },
      {
        selector: '.go-to-comm-center',
        route: '/claims',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'comm-center',
        },
      },
      {
        selector: '.go-to-pharmacy-card',
        route: '/pharmacy-card',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
        },
      },
      {
        selector: '.go-to-documents',
        route: '/claims',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'DOCUMENTS',
        },
      },
      {
        selector: '.go-to-documents-upload',
        route: '/upload-documents-in-documentstab',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'documents',
          showDialog: true,
        },
      },
      {
        selector: '.go-to-documents-show',
        route: '/upload-documents-in-documentstab',
        queryParams: {
          source: this.learningCenter.claimInfo.source,
          claimId: this.learningCenter.claimInfo.claimId,
          focus: 'documents',
          showDialog: false,
        },
      },
    ];
    this.checkViaClaim();
  }

  buildDocumentLink() {
    if (!this.isMgr)
      this.documentUrl = `/claims?source=${this.learningCenter.claimInfo.source}&claimId=${this.learningCenter.claimInfo.claimId}&focus=DOCUMENTS`;
  }

  openFileLink(event: MouseEvent) {
    event.stopPropagation();
    this.openFileService.openfile();
  }

  reportClaim(event: MouseEvent) {
    event.preventDefault();
    reportNewClaim(this.dialog);
  }

  openLearnMore(event: MouseEvent) {
    event.preventDefault();
    const element = event.target as HTMLElement;
    let url = element.getAttribute('href');
    const dialogRef = this.dialog.open(ExternalLinkDialogComponent);
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        window.open(url, '_blank');
      }
    });
  }

  checkViaClaim() {
    if (this.learningCenter.claimInfo.claimId != null) {
      this.isShown = !this.isShown;
    }
  }

  ngOnDestroy(): void {
    this.unlistens.forEach((unlisten) => unlisten());
    this.subs.unsubscribe();
  }

  checkHeaderValues() {
    if (this.checkArrayValue(this.learningCenter.introductionResults, 0)) {
      this.isIntroduction = true;
    }
    if (this.checkArrayValue(this.learningCenter.introductionResults, 2)) {
      this.isWhatisLV = true;
    }
    if (this.checkArrayValue(this.learningCenter.fmlaDetails, 0)) {
      this.isFMLADetails = true;
    }
    if (this.checkArrayValue(this.learningCenter.whatsnexts, 0)) {
      this.isWhatsnext = true;
    }

    if (this.checkArrayValue(this.learningCenter.afterDecide, 0)) {
      this.isAfterdecision = true;
    }
  }
  checkArrayValue(arr: any[], index: number) {
    const arrVal = arr.slice();
    if (index > 0) {
      arrVal.splice(index - 1, 1);
      return arrVal.some((obj) => obj.value !== '');
    } else {
      return arrVal.some((obj) => obj.value !== '');
    }
  }
}
