import { Injectable } from '@angular/core';
import { UserActionService, getUrlParams } from 'src/app/shared';
import { ClaimService } from './claim.service';
import { Http } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class ClaimantService {
  constructor(
    private userActions: UserActionService,
    public claimService: ClaimService,
    private http: Http
  ) {}
  async getEmpId() {
    const params = getUrlParams();
    let empID = null;

    const claimant = this.userActions.getClaimant();
    if (claimant && claimant?.empId) {
      empID = claimant.empId;
    } else if (claimant && claimant?.employeeID) {
      empID = claimant.employeeID;
    } else if (
      empID != null &&
      !empID &&
      //!empID.length &&
      params.source &&
      params.claimId
    ) {
      const claim = await this.claimService.getSummary(
        params.source,
        params.claimId
      );
      if (claim) {
        if (claim.empId) {
          empID = claim.empId;
        } else if (claim.employeeId) {
          empID = claim.employeeId;
        }
      }
    }
    if (!empID || !empID.length) {
      //console.error('empID is Undefined');
    }

    return empID;
  }

  async submitClaimantAbsence(absence: Absence): Promise<string> {
    return this.http.post<string>('/api/claimant/submit-absence', absence, {
      responseType: 'text',
    });
  }

  async GetAbsenceCategorys(): Promise<any[]> {
    return this.http.get<any[]>('/api/claimant/get-absence-categorys', {
      cache: true,
    });
  }

  async GetIncidentalAbsences(empUnum: string): Promise<any[]> {
    return this.http.get<any[]>(
      `/api/claimant/get-incidental-absences/${empUnum}`,
      {
        cache: true,
      }
    );
  }

  async DeleteAbsence(IncidentalAbsenceId: string): Promise<string> {
    return this.http.get<string>(
      `/api/claimant/delete-absence/${IncidentalAbsenceId}`,
      {
        cache: true,
      }
    );
  }

  GetEmployeeLabel(
    firstName: string,
    lastName: string,
    fullName: string,
    empId: string
  ): string {
    let employeeLabel = fullName ? fullName : firstName + ' ' + lastName;

    employeeLabel = empId ? employeeLabel + ' - ' + empId : employeeLabel;

    return employeeLabel;
  }
}

export class Absence {
  dateOfAbsence: Date;
  dateOfReturn?: Date;
  hours: string;
  minutes: string;
  reason: string;
  empUnum: string;
  employeeId: string;
  employeeName: string;
  absenceCategory: string;
}
