import { Directive, Injectable } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { parse } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
@Directive({
  selector: '[appNoWhiteSpace]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: customvalidations, multi: true },
  ],
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class customvalidations {
  static textControlValidations: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const hasWhiteSpace =
      control.value == null
        ? false
        : (control.value as string).indexOf(' ') >= 0;
    const specialCharRegEx: RegExp = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    const hasSpecialCharacters =
      control.value == null ? false : control.value.match(specialCharRegEx);
    if (hasWhiteSpace) {
      if (hasSpecialCharacters) {
        return { noWhiteSpaceAndSpecialChar: true };
      } else {
        return { noWhiteSpace: true };
      }
    } else {
      if (hasSpecialCharacters) {
        return { noSpecialCharacters: true };
      }
    }
    return null;
  };

  static cannotContainWhiteSpace: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const hasWhiteSpace = (control.value as string).indexOf(' ') >= 0;
    return hasWhiteSpace ? { noWhiteSpace: true } : null;
  };

  static cannotContainSpecialCharacters: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const specialCharRegEx: RegExp = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    // const hasSpecialCharacters = control.value.match(specialCharRegEx);
    const hasSpecialCharacters =
      control.value == null ? false : control.value.match(specialCharRegEx);
    return hasSpecialCharacters ? { noSpecialCharacters: true } : null;
  };

  static greaterThan(
    startControl: AbstractControl,
    includeEqual: boolean = true,
    customMsg: string = null
  ): ValidatorFn {
    return (endControl: AbstractControl): ValidationErrors | null => {
      const startDate: Date = startControl.value;
      const endDate: Date = endControl.value;
      if (!startDate || !endDate) {
        return null;
      }
      if (
        (includeEqual && startDate >= endDate) ||
        (!includeEqual && startDate > endDate)
      ) {
        return { greaterThan: true, customMsg };
      }
      return null;
    };
  }

  static dateGreaterThan(
    startControl: AbstractControl,
    includeEqual: boolean = true,
    customMsg: string = null
  ): ValidatorFn {
    return (endControl: AbstractControl): ValidationErrors | null => {
      const startDate: Date = new Date(startControl.value);
      const endDate: Date = new Date(endControl.value);

      if (!startDate || !endDate) {
        return null;
      }
      if (
        (includeEqual && startDate >= endDate) ||
        (!includeEqual && startDate > endDate)
      ) {
        return { greaterThan: true, customMsg };
      }
      return null;
    };
  }

  // verifies if a single date control value is between any provided date range
  static dateWithinRanges(
    dateRanges: { startDate: Date; endDate: Date }[],
    customMsg: string
  ): ValidatorFn {
    return (startControl: AbstractControl): ValidationErrors | null => {
      if (startControl.errors !== null) {
        return null;
      }
      const ranges = dateRanges;
      if ((ranges?.length || 0) == 0) {
        return null;
      }

      const startDate = this.dateonly(new Date(startControl.value));
      const overlaps = ranges.filter(
        (dr) =>
          this.dateonly(new Date(dr.endDate)).getTime() >=
            startDate.getTime() &&
          startDate.getTime() >= this.dateonly(new Date(dr.startDate)).getTime()
      ).length;

      if (overlaps === 0 && startControl.errors !== null) {
        startControl.updateValueAndValidity();
      }
      return overlaps === 0 ? null : { dateOverlaps: true, customMsg };
    };
  }

  static dateonly = (d: Date): Date => {
    return new Date(d.toDateString());
  };

  static dateLessThan(
    startControl: AbstractControl,
    includeEqual: boolean = true,
    customMsg: string = null
  ): ValidatorFn {
    return (endControl: AbstractControl): ValidationErrors | null => {
      const startDate: Date = new Date(startControl.value);
      const endDate: Date = new Date(endControl.value);
      if (
        startDate != null &&
        startDate != undefined &&
        startDate.getFullYear() > 1970
      ) {
        if (!startDate || !endDate) {
          return null;
        }
        if (
          (includeEqual && startDate <= endDate) ||
          (!includeEqual && startDate < endDate)
        ) {
          return { greaterThan: true, customMsg };
        }
      }
      return null;
    };
  }

  static lessThan(
    startControl: AbstractControl,
    customMsg: string
  ): ValidatorFn {
    return (endControl: AbstractControl): ValidationErrors | null => {
      const startDate: Date = startControl.value;
      const endDate: Date = endControl.value;
      if (!startDate || !endDate) {
        return null;
      }
      if (startDate <= endDate) {
        return { lessThan: true, customMsg };
      }
      return null;
    };
  }

  static timeGreaterThan(startControl: AbstractControl): ValidatorFn {
    return (endControl: AbstractControl): ValidationErrors | null => {
      if (endControl.value != null) {
        const start = this.convertTime(startControl.value);
        const end = this.convertTime(endControl.value);
        // var start = parse(
        //   startControl.value,
        //   'hh:mm a',
        //   new Date()
        // );
        // var end = parse(
        //   endControl.value,
        //   'hh:mm a',
        //   new Date()
        // );
        //console.log('checking time greater than', start, end);
        if (!start || !end) {
          return null;
        }
        if (start >= end) {
          return { timeGreaterThan: true };
        }
      }
      return null;
    };
  }

  static timeGreaterThanHours(
    startControl: AbstractControl,
    maxHours: number,
    customMsg: string = null
  ): ValidatorFn {
    return (endControl: AbstractControl): ValidationErrors | null => {
      if (endControl.value != null) {
        //var start = this.convertTime(startControl.value);
        //var end = this.convertTime(endControl.value);
        const start = parse(startControl.value, 'hh:mm a', new Date());
        const end = parse(endControl.value, 'hh:mm a', new Date());

        if (!start || !end) {
          return null;
        } else {
          const diffMs = end.getTime() - start.getTime();
          const diffHours = Math.abs(diffMs / (1000 * 60 * 60));
          if (diffHours > maxHours) {
            return { timeGreaterThanHours: true, customMsg };
          }
        }
      }
      return null;
    };
  }

  static dateInFuture(
    customMsg: string = null,
    baseDateVal: Date = null
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !(
        control.value == null ||
        control.value === undefined ||
        control.value === ''
      ) && new Date(control.value) > (baseDateVal ?? new Date())
        ? {
            dateInFuture: true,
            customMsg: (customMsg ?? 'Date can not be in future') + baseDateVal,
          }
        : null;
    };
  }

  static numberLessThan(
    maxValue: number,
    customMsg: string = null
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !(
        control.value == null ||
        control.value === undefined ||
        control.value === ''
      ) && Number(control.value) > maxValue
        ? {
            numberLessThan: true,
            customMsg: customMsg ?? 'Value exceeds maximun.',
          }
        : null;
    };
  }

  static numberGreaterThan(
    minValue: number,
    customMsg: string = null
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !(
        control.value == null ||
        control.value === undefined ||
        control.value === ''
      ) && Number(control.value) < minValue
        ? {
            numberGreaterThan: true,
            customMsg: customMsg ?? 'Error : The value entered is not valid',
          }
        : null;
    };
  }

  static numberMustIncrementBy(
    increment: number,
    customMsg: string = null
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !(
        control.value == null ||
        control.value === undefined ||
        control.value === ''
      ) && Number(control.value) % increment !== 0
        ? {
            numberMustIncrementBy: true,
            customMsg: customMsg ?? 'Increment interval is not valid.',
          }
        : null;
    };
  }

  static dateInFutureOld: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    // const specialCharRegEx: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    // const hasSpecialCharacters = control.value.match(specialCharRegEx);
    // const hasSpecialCharacters = control.value == null ? false : control.value.match(specialCharRegEx);
    // return hasSpecialCharacters ? { noSpecialCharacters: true } : null;
    const r =
      !(
        control.value == null ||
        control.value === undefined ||
        control.value === ''
      ) && new Date(control.value) > new Date()
        ? { dateInFuture: true, customMsg: 'Date can not be in future' }
        : null;
    return r;
  };

  static required: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    // const specialCharRegEx: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    // const hasSpecialCharacters = control.value.match(specialCharRegEx);
    // const hasSpecialCharacters = control.value == null ? false : control.value.match(specialCharRegEx);
    // return hasSpecialCharacters ? { noSpecialCharacters: true } : null;
    return control.value == null ||
      control.value === undefined ||
      control.value === ''
      ? { required: true }
      : null;
  };

  //static changeTimeFormat(timeStr: any, secondTimeStr: any) {
  static convertTime = (timeStr: { split: (arg0: string) => [any, any] }) => {
    const [time, modifier] = timeStr.split(' ');
    // eslint-disable-next-line prefer-const
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (
      (modifier == 'pm' || modifier == 'PM') &&
      modifier != '' &&
      modifier.length === 2
    ) {
      hours = parseInt(hours, 10) + 12;
    }
    //minutes = minutes +1 -1;
    return `${hours}:${minutes}`;
  };
  //}

  static IsNumeric(customMsg: string = null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const res = /^\d*$/.test(control.value);
      return res == false
        ? { customMsg: customMsg ?? 'Error : Please enter a number' }
        : null;
    };
  }

  static MaxDate(maxDate: Date, customMsg: string = null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return new Date(control.value) > maxDate
        ? {
            customMsg:
              customMsg ??
              `Error : Date can not be greater than ${maxDate.toLocaleDateString(
                'en-US'
              )}`,
          }
        : null;
    };
  }

  static MinDate(minDate: Date, customMsg: string = null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return new Date(control.value) < minDate
        ? {
            customMsg:
              customMsg ??
              `Error : Date can not be less than ${minDate.toLocaleDateString(
                'en-US'
              )}`,
          }
        : null;
    };
  }

  // isCurrency allows a decimal point whereas isNumeric does not
  static IsDecimal(customMsg: string = null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const res = !isNaN(control.value);
      return res == false
        ? { customMsg: customMsg ?? 'Error : Please enter a number' }
        : null;
    };
  }
  static validZipCode: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const res = /^\d{5}$/.test(control.value);
    return res == false ? { noValidZipCode: true } : null;
  };

  static matchTextControls(
    startControl: AbstractControl,
    customMsg: string = null
  ): ValidatorFn {
    return (endControl: AbstractControl): ValidationErrors | null => {
      const firstControlVal: string = startControl.value;
      const secondControlVal: string = endControl.value;

      if (firstControlVal != secondControlVal) {
        return { missMatchControl: true, customMsg };
      }
      return null;
    };
  }

  static onlyNumeric(customMsg: string = null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!/^\d+$/.test(control.value)) {
        return { invaliddata: true, customMsg };
      } else {
        return null;
      }
    };
  }

  static RountingValidate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const routingNumber = control.value;
      if (!/^\d{9}$/.test(routingNumber)) {
        return {
          invalidrouting: true,
          customMsg: 'Routing number must be 9 digits',
        };
      }

      //split the routing number into its individual digits
      const digits = routingNumber.split('').map(Number);

      //calculate the checksum using the ABA algorithm
      const checksum =
        3 * (digits[0] + digits[3] + digits[6]) +
        7 * (digits[1] + digits[4] + digits[7]) +
        (digits[2] + digits[5] + digits[8]);

      //if the checksum is a multiple of 10, the routing number is valid
      if (checksum % 10 === 0) {
        return null;
      } else {
        return {
          invalidrouting: true,
          customMsg: 'Error : Valid routing number is required',
        };
      }
    };
  }

  static MileageReimbursementTotalMiles(
    roundTripControl: AbstractControl,
    minMiles?: number,
    maxMiles?: number,
    roundTripRule?: string,
    customMsg: string = null
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let travelDistance = Number(control.value);

      if (
        !(
          minMiles !== undefined ||
          maxMiles !== undefined ||
          roundTripRule !== undefined
        )
      ) {
        console.log(
          'invalid settings, ignoring validation for reimbursement miles'
        );
        return null;
      }
      const roundTripSelected = roundTripControl.value === 'YES' ? true : false;

      if (roundTripSelected === false) {
        // set travelDistance value to round trip total
        travelDistance = travelDistance * 2;
      }
      const roundTripLimit = roundTripRule === 'RT' ? true : false;

      let result;
      if (!roundTripLimit) {
        // ONE-WAY limitation, need to adjust values for min and maxMiles
        // one-way limit and Round trip input
        if (minMiles !== undefined && minMiles * 2 > travelDistance) {
          //
          result = {
            minMilesError: true,
            customMsg:
              'The minimum mileage requirement has not been met for reimbursement',
          };
        }
        if (maxMiles !== undefined && maxMiles * 2 < travelDistance) {
          result = {
            maxMilesError: true,
            customMsg:
              'The maximum mileage requirement has been exceeded for reimbursement',
          };
        }
      } else {
        // ROUND TRIP limitation
        if (minMiles !== undefined && minMiles > travelDistance) {
          result = {
            minMilesError: true,
            customMsg:
              'The minimum mileage requirement has not been met for reimbursement',
          };
        }
        if (maxMiles !== undefined && maxMiles < travelDistance) {
          result = {
            maxMilesError: true,
            customMsg:
              'The maximum mileage requirement has been exceeded for reimbursement',
          };
        }
      }
      //console.log("MILEAGE VALIDATION", result);
      return result;
    };
  }

  static dateNotMoreThanMonthInFuture(
    customMsg: string = 'Date cannot be more than one month in the future'
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const currentDate = new Date();
      const inputDate = new Date(control.value);

      if (!inputDate || isNaN(inputDate.getTime())) {
        return null;
      }

      const maxDate = new Date(currentDate);
      maxDate.setMonth(maxDate.getMonth() + 1);

      if (inputDate > maxDate) {
        return {
          dateNotMoreThanMonthInFuture: true,
          customMsg,
        };
      }

      return null;
    };
  }

  static beginDateNotAfterEndDate(
    customMsg: string = 'Error : Begin date cannot be after end date'
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }
      const beginDate = new Date(control.value);
      const endDate = new Date(control.parent.get('endDate').value);

      if (
        !beginDate ||
        !endDate ||
        isNaN(beginDate.getTime()) ||
        isNaN(endDate.getTime())
      ) {
        return null;
      }

      if (beginDate > endDate) {
        return { beginDateNotAfterEndDate: true, customMsg };
      }

      return null;
    };
  }

  static endDateNotBeforeBeginDate(
    customMsg: string = 'Error : End date cannot be before start date'
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }
      const endDate = new Date(control.value);
      const beginDate = new Date(control.parent.get('startDate').value);

      if (
        !beginDate ||
        !endDate ||
        isNaN(beginDate.getTime()) ||
        isNaN(endDate.getTime())
      ) {
        return null;
      }

      if (beginDate > endDate) {
        return { beginDateNotAfterEndDate: true, customMsg };
      }

      return null;
    };
  }

  static returnToWorkDateNotBeforeBeginDate(
    customMsg: string = 'Return to work date cannot be before begin date'
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control || !control.parent || control.value == null) {
        return null;
      }

      const returnToWorkDate = new Date(control.value);
      const beginDate = new Date(control.parent.get('startDate').value);

      if (
        !beginDate ||
        !returnToWorkDate ||
        isNaN(beginDate.getTime()) ||
        isNaN(returnToWorkDate.getTime())
      ) {
        return null;
      }

      if (returnToWorkDate < beginDate) {
        return { returnToWorkDateNotBeforeBeginDate: true, customMsg };
      }

      return null;
    };
  }

  static returnToWorkDateNotBeforeEndDate(
    customMsg: string = 'Error: Return to work date cannot be before end date'
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control || !control.parent) {
        return null;
      }

      if (!control.value) {
        return null;
      }

      const returnToWorkDate = new Date(control.value);
      const endDate = control.parent.get('endDate')?.value;

      if (!endDate) {
        return null;
      }

      const endDateFormatted = new Date(endDate);

      if (
        isNaN(endDateFormatted.getTime()) ||
        isNaN(returnToWorkDate.getTime())
      ) {
        return null;
      }

      if (returnToWorkDate < endDateFormatted) {
        return {
          returnToWorkDateNotBeforeBeginDate: true,
          customMsg,
        };
      }

      return null;
    };
  }
}
